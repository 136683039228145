import React from 'react';
import {Loader, MessageView} from '../../../@crema';
import {useSelector} from 'react-redux';
import { useIntl } from "react-intl";

const InfoView = () => {
  const {error, loading, message} = useSelector(({common}) => common);

  const { messages } = useIntl();

  const showMessage = () => {
    return <MessageView variant='success' message={message.toString()} />;
  };
  const showError = () => {
    return <MessageView variant='error' message={error.toString()} />;
  };

  const element_main = document.getElementsByClassName("main-content-view")[0];
  const element_auth = document.getElementsByClassName("auth-content-view")[0];
  let element = null;
  if (element_auth){
    element=element_auth
  }
  else{
    if (element_main){
      element=element_main
    }
  }

  return (
    <>
      {loading && <><Loader height={element ? element.clientHeight : "100%"}/> <MessageView variant='info' message={messages["app.save_loading"]} no_close={true} /></>}
      {message && showMessage()}
      {error && showError()}
    </>
  );
};

export default InfoView;
