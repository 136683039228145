import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles } from "@material-ui/core/styles";


/*ESTILOS PERSONALIZADOS DEL COMPONENTE */
const useStyles = makeStyles((theme) => {
  return {
    loader: {
      backgroundColor: "rgba(0, 0, 0, .1)"
    }
  };
});
/*FIN ESTILOS PERSONALIZADOS DEL COMPONENTE */

const Loader = (props) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.loader}
      height={props.height}
      display='flex'
      flex={1}
      alignItems='center'
      justifyContent='center'
      position='absolute'
      top={0}
      left={0}
      right={0}
      bottom={0}>
      {/* <CircularProgress /> */}
    </Box>
  );
};

export default Loader;
