export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 4,
};
export const ThemeMode = {
  LIGHT: 'light',
  SEMI_DARK: 'semi-dark',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width'
};
export const NavStyle = {
  HEADER_USER: 'user-header'
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  DARK: 'dark',
  LIGHT: 'light',
};
export const RouteTransition = {
  NONE: 'none',
  FADE: 'fade',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: 'SourceSansPro-Light',
  REGULAR: 'SourceSansPro-Regular',
  MEDIUM: 'SourceSansPro-Regular',
  BOLD: 'SourceSansPro-Bold',
  EXTRA_BOLD: 'SourceSansPro-Black',
};

export const AuthType = {
  JWT_AUTH: 'jwt_auth',
};

export const Locale = {
  "es-ES": 'es',
  "spa": 'es',
  "eu": 'eus',
  "eus": 'eus',
};

export const Language = {
  "es": 'spa',
  "eu": 'eus'
};

export const Social = {
  "facebook": 'Facebook',
  "twitter": 'Twitter',
  "instagram": 'Instagram',
  "linkedin": 'Linkedin',
  "youtube": 'Youtube',
  "pinterest": 'Pinterest',
  "tripadvisor": 'Tripadvisor'
};