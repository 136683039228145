import React from "react";
import Product from "./Product";

export const adminConfigs = [
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/general_data/data",
        component: React.lazy(() => import("./GeneralData")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/general_data/photos",
        component: React.lazy(() => import("./Photos")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/products/list",
        component: React.lazy(() => import("./ProductsList")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/product/:id",
        component: React.lazy(() => import("./Product")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/product",
        component: Product,
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/orders",
        component: React.lazy(() => import("./Orders")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/order/:id",
        component: React.lazy(() => import("./Order")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/refunds",
        component: React.lazy(() => import("./RefundsList")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/refund/:id",
        component: React.lazy(() => import("./Refund")),
      },
    ],
  },
  {
    auth: ["user"],
    routes: [
      {
        path: "/admin/invoices",
        component: React.lazy(() => import("./Invoices")),
      },
    ],
  },
];
