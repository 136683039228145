import {
  SET_IS_EDIT_FORM,
  SET_SHOW_DIALOG_CHANGE_PAGE,
  SET_SHOW_DIALOG_DELETE,
  SET_SHOW_DIALOG_ATTRIBUTES,
  SET_SHOW_DIALOG_PRODUCT_ATTRIBUTE,
  SET_SHOW_DIALOG_OK,
  SET_SHOW_DIALOG_CONFIRM
} from "../../shared/constants/ActionTypes";

const INIT_STATE = {
  is_edit_form: false,
  show_dialog_change_page: false,
  show_dialog_attributes: false,
  show_dialog_product_attribute: false,
  show_dialog_ok: false,
  show_dialog_confirm: false
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_IS_EDIT_FORM: {
      return {
        ...state,
        is_edit_form: action.payload,
      };
    }

    case SET_SHOW_DIALOG_CHANGE_PAGE: {
      return {
        ...state,
        show_dialog_change_page: action.payload,
      };
    }

    case SET_SHOW_DIALOG_DELETE: {
      return {
        ...state,
        show_dialog_delete: action.payload,
      };
    }

    case SET_SHOW_DIALOG_ATTRIBUTES: {
      return {
        ...state,
        show_dialog_attributes: action.payload,
      };
    }

    case SET_SHOW_DIALOG_PRODUCT_ATTRIBUTE: {
      return {
        ...state,
        show_dialog_product_attribute: action.payload,
      };
    }

    case SET_SHOW_DIALOG_OK: {
      return {
        ...state,
        show_dialog_ok: action.payload,
      };
    }

    case SET_SHOW_DIALOG_CONFIRM: {
      return {
        ...state,
        show_dialog_confirm: action.payload,
      };
    }

    default:
      return state;
  }
};
