import { useEffect, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "../../@crema/utility/AppContext";
import {
  UPDATE_JWT_AUTH_USER,
  UPDATE_JWT_AUTH_TOKEN,
  SET_LOCALE,
  GET_STORE_DATA,
} from "../../shared/constants/ActionTypes";
import { AuthType } from "../../shared/constants/AppEnums";

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [store, setStore] = useState(null);
  // const [locale, setLocale] = useState(null);
  const jwtAuth = useSelector(({ jwtAuth }) => jwtAuth);

  const { setContentLanguage } = useContext(AppContext);

  useEffect(() => {
    const jwtAthCheck = () =>
      new Promise((resolve) => {
        const user = localStorage.getItem("jwt-auth-user");
        const token = localStorage.getItem("token-toptopa");
        const locale = localStorage.getItem("locale");
        const store = localStorage.getItem("store");
        dispatch({
          type: UPDATE_JWT_AUTH_USER,
          payload: JSON.parse(user),
        });
        dispatch({
          type: UPDATE_JWT_AUTH_TOKEN,
          payload: token,
        });
        dispatch({
          type: SET_LOCALE,
          payload: locale,
        });
        dispatch({
          type: GET_STORE_DATA,
          payload: store,
        });
        resolve();
        return Promise.resolve();
      });

    const checkAuth = () => {
      jwtAthCheck().then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);
  useEffect(() => {
    if (!jwtAuth.user) {
      setToken(null);
      setUser(null);
    }
    if (jwtAuth.token) {
      setToken(jwtAuth.token);
      localStorage.setItem("token-toptopa", jwtAuth.token);
    }
    if (jwtAuth.user) {
      setUser(jwtAuth.user);
    }
    if (jwtAuth.locale) {
      // setLocale(jwtAuth.locale);
      setContentLanguage(jwtAuth.locale);
      localStorage.setItem("locale", jwtAuth.locale);
    }

    if (jwtAuth.store) {
      setStore(jwtAuth.store);
      localStorage.setItem("store", jwtAuth.store);
    }
  }, [jwtAuth]);
  return [token, loading, user, store];
};

export const useAuthUser = () => {
  const jwtAuth = useSelector(({ jwtAuth }) => jwtAuth);
  if (jwtAuth) {
    return [
      { ...jwtAuth.user },
      { ...jwtAuth.token },
      { ...jwtAuth.store },
      AuthType.JWT_AUTH,
    ];
  }
  return [null];
};
