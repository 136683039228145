import React, { useContext } from "react";
import { map } from "underscore";
import Avatar from "@material-ui/core/Avatar";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { onJWTAuthSignout } from "../../../redux/actions";
import { useAuthUser } from "../../../@crema/utility/AppHooks";
import AppContext from "../../../@crema/utility/AppContext";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Box, Menu, MenuItem, Select } from "@material-ui/core";

import { grey, purple } from "@material-ui/core/colors";
import { Fonts, ThemeMode } from "../../constants/AppEnums";
import Hidden from "@material-ui/core/Hidden";

/*IDIOMAS*/
import IntlMessages from "../../../@crema/utility/IntlMessages";
import { useIntl } from "react-intl";
/*FIN IDIOMAS*/

import {
  onSetShowDialogChangePage,
  onSetFutureURL,
  onUpdateUser,
} from "../../../redux/actions";

const HeaderUser = (props) => {
  const {
    themeMode,
    contentLanguage,
    setContentLanguage,
    changeLocale,
  } = useContext(AppContext);
  const dispatch = useDispatch();
  const [user] = useAuthUser();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user.name) {
      return user.name.charAt(0).toUpperCase();
    }
    if (user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };

  const useStyles = makeStyles((theme) => {
    return {
      crHeaderUser: {
        backgroundColor: props.header ? "transparent" : "rgba(0,0,0,.08)",
        paddingTop: 9,
        paddingBottom: 9,
        minHeight: 56,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        [theme.breakpoints.up("sm")]: {
          paddingTop: 10,
          paddingBottom: 10,
          minHeight: 70,
        },
      },
      profilePic: {
        height: 40,
        width: 40,
        fontSize: 24,
        backgroundColor: purple[500],
        [theme.breakpoints.up("xl")]: {
          height: 45,
          width: 45,
        },
      },
      Pic: {
        height: 50,
      },
      // userInfo: {
      //   width: !props.header ? 'calc(100% - 75px)' : '100%',
      // },
      userName: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        cursor: "pointer",
        whiteSpace: "nowrap",
        fontSize: 16,
        fontFamily: Fonts.MEDIUM,
        [theme.breakpoints.up("xl")]: {
          fontSize: 18,
        },
        color:
          themeMode === ThemeMode.DARK || !props.header ? "white" : "#313541",
      },
      pointer: {
        cursor: "pointer",
      },
      adminRoot: {
        color: grey[500],
        fontSize: 16,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
      addButton: {
        marginRight: 20,
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
      language: {
        color: theme.palette.primary.main,
        paddingTop: 0,
        marginLeft: 10,
        marginRight: 20,
        "& svg": {
          color: theme.palette.primary.main,
        },
      },
      container_language: {
        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },
    };
  });

  const classes = useStyles(props);

  const history = useHistory();

  const isEditForm = useSelector(({ forms }) => forms.is_edit_form); //Comprobar si se está editando un formulario y no ha sido guardado
  const storeData = useSelector(({ store }) => store.storeData); //Datos de la tienda, para mostrar el logotipo si lo tuviera
  const configData = useSelector(({ config }) => config.configData); //Datos para la carga de idiomas
  const userData = useSelector(({ jwtAuth }) => jwtAuth); //Datos del usuario

  const handleOnClikNew = (e) => {
    history.push("/admin/product/");
  };

  function handleLink(event, url) {
    event.preventDefault();
    if (isEditForm) {
      dispatch(onSetShowDialogChangePage(true));
    } else {
      history.push(url);
    }
    dispatch(onSetFutureURL(url));
  }

  const languages = configData ? configData.languages : null;
  const { messages } = useIntl();

  const handleChangeLanguage = (e) => {
    const user = {
      id: userData.user.id,
      locale: e.target.value,
    };
    dispatch(onUpdateUser(user, setContentLanguage, changeLocale, userData.token));
  };

  return (
    <Box
      px={{ xs: 4, xl: 7 }}
      className={clsx(classes.crHeaderUser, "cr-user-info")}
    >
      <Box display="flex" alignItems="center">
        {storeData ? (
          storeData.id ? (
            <Button
              className={classes.addButton}
              variant="contained"
              size="large"
              color="primary"
              onClick={handleOnClikNew}
            >
              <IntlMessages id="sidebar.app.add.product" />
            </Button>
          ) : null
        ) : null}

        {languages && (
          <div className={classes.container_language}>
            {messages["products.list.change_language"]}
            <Select
              className={classes.language}
              value={contentLanguage}
              onChange={handleChangeLanguage}
            >
              {map(languages, (value) => {
                return (
                  <MenuItem key={value.id} value={value.iso3} name={value.id}>
                    {value.name}
                  </MenuItem>
                );
              })}
            </Select>
          </div>
        )}

        {storeData ? (
          storeData.logo ? (
            storeData.logo.paths ? (
              <img
                alt="logo"
                className={classes.Pic}
                src={`${process.env.REACT_APP_WEB_URL}${storeData.logo.paths.thm}`}
              />
            ) : (
              <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
            )
          ) : (
            <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
          )
        ) : (
          <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>
        )}

        <Box ml={4} className={clsx(classes.userInfo, "user-info")}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Hidden mdDown>
              <Box mb={1} className={clsx(classes.userName)}>
                {user.name ? user.name : user.email}
                <Box fontSize={14} color="text.secondary">
                  Admin
                </Box>
              </Box>
            </Hidden>
            <Box
              ml={{ md: 3 }}
              className={classes.pointer}
              color={
                themeMode === ThemeMode.DARK || !props.header
                  ? "white"
                  : "#404040"
              }
            >
              <ExpandMoreIcon onClick={handleClick} />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem
                  onClick={(e) => handleLink(e, "/admin/general_data/data")}
                >
                  <IntlMessages id="app.data" />
                </MenuItem>
                <MenuItem
                  onClick={(e) => {
                    dispatch(onJWTAuthSignout(userData.token));
                  }}
                >
                  <IntlMessages id="app.logout" />
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderUser;
HeaderUser.defaultProps = {
  header: true,
};