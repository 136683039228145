import React, {useContext, useEffect } from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';

// import {setInitialPath} from '../../redux/actions'; 
import {matchRoutes} from 'react-router-config';
import AppContext from './AppContext';
import {useAuthToken} from './AppHooks';
import {Loader} from '../index';
import PropTypes from 'prop-types';

import AlertFormEdit from '../../../src/modules/muiComponents/feedback/Dialogs/AlertFormEdit'; //Añadido para el control de edición de formulario

import {onSetIsEditForm,onSetShowDialogChangePage} from '../../redux/actions';

const AuthRoutes = ({children}) => {
  const {pathname} = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {routes} = useContext(AppContext);

  const [token, loading, user] = useAuthToken();
  const initialPath = useSelector(({settings}) => settings.initialPath);
  const currentRoute = matchRoutes(routes, pathname)[0].route;

  const actual_url = useSelector(({urls}) => urls.actual_url);

  function handleAgree() {
    dispatch(onSetIsEditForm(false));
    dispatch(onSetShowDialogChangePage(false));
    history.push(actual_url);
  }
  // useEffect(() => {
  //   function setInitPath() {
  //     if (
  //       initialPath === '/' &&
  //       [
  //         '/signin',
  //         '/signup',
  //         '/complete-register',
  //         '/reset-password',
  //         '/forget-password',
  //         '/complete-register/:salt'
  //       ].indexOf(pathname) === -1
  //     ) {
  //       dispatch(setInitialPath(pathname));
  //     }
  //   }
  //   setInitPath();
  // }, [dispatch, initialPath, loading, pathname, token]);

  useEffect(() => {
      if (!loading) {
        if ((!token || !user) && currentRoute.auth && currentRoute.auth.length >= 1) {
          if(localStorage.getItem("user-superadmin")){
            history.push('/signin-admin');
          }
          else{
            history.push('/signin');
          }
        } else if (
          ( pathname === '/signin-admin' ||
            pathname === '/signin' ||
            pathname === '/signup' ||
            pathname === '/confirm-signup' ||
            pathname === '/reset-password' ||
            pathname === '/forget-password' ||
            pathname === '/complete-register/:salt') &&
            token
        ) {
          if (pathname === '/') {
            history.push('/admin/general_data/data');
          } else if (
            initialPath !== '/' ||
            initialPath !== '/signin' ||
            initialPath !== '/signup'
          ) {
            history.push(initialPath);
          } else {
            history.push('/admin/general_data/data');
          }
        }
      }
  }, [token, loading, pathname, initialPath, currentRoute.auth, history]);
  
  return loading ? <Loader /> : <>{children}<AlertFormEdit handleAgree={handleAgree} title="app.aviso" message="dialog.form.is.edit"></AlertFormEdit></>;
};

export default AuthRoutes;

AuthRoutes.propTypes = {
  children: PropTypes.node.isRequired,
};