const routesConfig = [
  {
    id: 'general_data',
    title: 'General data',
    messageId: 'sidebar.app.general_data',
    type: 'collapse',
    icon: 'icon-menu icon-datos-generales',
    children: [
      {
        id: 'general_data',
        title: 'Datos generales',
        messageId: 'sidebar.app.general_data.data',
        type: 'item',
        url: '/admin/general_data/data',
      },
      {
        id: 'photos',
        title: 'Localización y fotos',
        messageId: 'sidebar.app.general_data.localization_photos',
        type: 'item',
        url: '/admin/general_data/photos',
      },
    ],
  },
  {
    id: 'products',
    title: 'Products',
    messageId: 'sidebar.app.products',
    type: 'collapse',
    icon: 'icon-menu icon-productos',
    children: [
      {
        id: 'product_list',
        title: 'Product list',
        messageId: 'sidebar.app.products.list',
        type: 'item',
        url: '/admin/products/list',
      },
      {
        id: 'add_product',
        title: 'Añadir producto',
        messageId: 'sidebar.app.add.product',
        type: 'item',
        url: '/admin/product',
        exact: true
      },
    ],
  },
  {
    id: 'orders',
    title: 'Orders',
    messageId: 'sidebar.app.orders',
    type: 'item',
    icon: 'icon-menu icon-pedidos',
    url: '/admin/orders',
  },
  {
    id: 'refunds',
    title: 'Refunds',
    messageId: 'sidebar.app.refunds',
    type: 'item',
    icon: 'icon-menu icon-devoluciones',
    url: '/admin/refunds',
  },
  {
    id: 'invoices',
    title: 'Invoices',
    messageId: 'sidebar.app.invoices',
    type: 'item',
    icon: 'icon-menu icon-facturas',
    url: '/admin/invoices',
  }
];
export default routesConfig;
