import axios from "axios";
import {
  UPDATE_JWT_AUTH_USER,
  UPDATE_JWT_AUTH_TOKEN,
  FETCH_ERROR,
} from "../../../shared/constants/ActionTypes";

const jwtAxios = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const jwtAxiosFiles = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "multipart/form-data",
  },
});

const InvalidToken = (error, dispatch) => {
  if (
    error.request &&
    (error.request.status === 401 || error.request.status === 500)
  ) {
    dispatch({
      type: UPDATE_JWT_AUTH_USER,
      payload: null,
    });
    dispatch({
      type: UPDATE_JWT_AUTH_TOKEN,
      payload: null,
    });
  } else {
    dispatch({ type: FETCH_ERROR, payload: error.message });
  }
};

export { jwtAxios, jwtAxiosFiles, InvalidToken };
