import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch,useSelector} from 'react-redux';
import {onSetShowDialogDelete} from '../../../../redux/actions';
import IntlMessages from '../../../../@crema/utility/IntlMessages';

export default function AlertFormDelete({action, index, title, message}) {
  const show_dialog_delete = useSelector(({forms}) => forms.show_dialog_delete);
  const dispatch = useDispatch();
  
  function handleAgree() {
    action(index);
    dispatch(onSetShowDialogDelete(false));
  }
  function handleClose() {
    dispatch(onSetShowDialogDelete(false));
  }
  function handleDisagree() {
    dispatch(onSetShowDialogDelete(false));
  }
  
  return ( show_dialog_delete ?
    <div>
      <Dialog
        open={show_dialog_delete}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <IntlMessages id={title}/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <IntlMessages id={message} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDisagree} color='secondary' variant="contained">
            <IntlMessages id='app.no' />
          </Button>
          <Button onClick={handleAgree} color='secondary' autoFocus variant="contained">
            <IntlMessages id='app.si' />
          </Button>
        </DialogActions>
      </Dialog>
    </div> : null
  );
}
