import {makeStyles} from '@material-ui/core';
import {useContext} from 'react';
import AppContext from '../../../utility/AppContext';
import {ThemeMode} from '../../../../shared/constants/AppEnums';

const useStyles = makeStyles((theme) => {
  // const {themeMode} = useContext(AppContext);
  return {
    container: {
      width: '19rem',
      // maxHeight: '100vh',
      verticalAlign: "top",
      display: "table-cell",
      zIndex: 12,
      position: "relative",
      [theme.breakpoints.up('xl')]: {
        width: '21.6rem',
      },
    },
    sidebarBg: {
      display: "table",
      backgroundColor: 'white',
        // themeMode === ThemeMode.SEMI_DARK ? theme.palette.sidebar.bgColor : themeMode === ThemeMode.LIGHT
        //   ? 'white'
        //   : '#313541',
    },
    scrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20
    },
    drawerScrollAppSidebar: {
      paddingTop: 8,
      paddingBottom: 20,
    },
    sidebarStandard: {
      height: '100%',
      width: '100%',
      color: 'white',
      overflow: 'hidden',
    },
  };
});
export default useStyles;
