import {navigationConfigs} from './navigation';
import {surfaceConfigs} from './surfaces';
import {dataDisplayConfigs} from './dataDisplay';
import {utilConfigs} from './util';

export const muiComponentConfigs = [
  ...navigationConfigs,
  ...surfaceConfigs,
  ...dataDisplayConfigs,
  ...utilConfigs,
];
