import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  SHOW_MESSAGE,
  SET_JWT_TOKEN_SET,
  SIGNOUT_JWT_USER_SUCCESS,
  GET_JWT_USER_DATA,
  GET_USER_COMPLETE_DATA,
  GET_STORE_DATA,
} from "../../shared/constants/ActionTypes";
import { jwtAxios } from "../../@crema/services/auth/";
import { Locale } from "../../shared/constants/AppEnums";

/*DATOS*/
import { onGetStore } from "../../redux/actions/Store";
/*FIN DATOS*/

/*COMPLETE REGISTRO*/
export const onGetUserComplete = (salt, history) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .get(`users/complete/${salt}`)
      .then(({ data }) => {
        if (data.user) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_USER_COMPLETE_DATA, payload: data });
        } else {
          history.push("/signin");
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch(function (error) {
        history.push("/signin");
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSetCompleteRegister = (data, history, salt) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .patch(`users/complete/${salt}`, {
        email: data.email,
        name: data.name,
        password: data.newPassword,
        password2: data.confirmPassword,
        locale: data.locale,
      })
      .then(({ data }) => {
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
          if (data.errors) {
            if (data.errors.validate.password) {
              dispatch({
                type: FETCH_ERROR,
                payload: data.errors.validate.password,
              });
            }
            if (data.errors.validate.email) {
              dispatch({
                type: FETCH_ERROR,
                payload: data.errors.validate.email,
              });
            }
          } else {
            dispatch({
              type: SHOW_MESSAGE,
              payload: data.message,
            });
            history.push("/signin");
          }
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.error });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.response.data.error });
      });
  };
};

/*LOGIN*/
export const onJwtSignIn = ({
  email,
  password,
  locale,
  changeLocale,
  setContentLanguage,
}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .post("users/login", {
        email: email,
        password: password,
        locale: locale,
      })
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem("jwt-auth-user", JSON.stringify(data.user));
          localStorage.setItem("token-toptopa", data.token);

          // Hago la petición al servidor para obtener la información de la tienda
          // y guardarla en local
          dispatch(onGetStore(data.token, true));

          //Cambiar el idioma del interfaz respecto al idioma del usuario
          changeLocale(
            Locale[data.user.locale]
              ? Locale[data.user.locale]
              : process.env.REACT_APP_DEFAULT_LOCALE_INTERFACE
          );
          setContentLanguage(data.user.locale);
          localStorage.setItem("locale", data.user.locale);
          changeLocale(Locale[data.user.locale]);

          dispatch({ type: GET_JWT_USER_DATA, payload: data.user });
          dispatch({ type: SET_JWT_TOKEN_SET, payload: data.token });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onJwtSignInSuperAdmin = ({
  email,
  password,
  locale,
  changeLocale,
  setContentLanguage,
  store_id,
}) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .post("users/login", {
        email: email,
        password: password,
        locale: locale,
        store_id: store_id,
      })
      .then(({ data }) => {
        if (data.success) {
          localStorage.setItem("jwt-auth-user", JSON.stringify(data.user));
          localStorage.setItem("token-toptopa", data.token);
          //Cambiar el idioma del interfaz respecto al idioma del usuario

          // Hago la petición al servidor para obtener la información de la tienda
          // y guardarla en local
          dispatch(onGetStore(data.token, true));

          //Para indicar que quien ha realizado el login es el superadministrador, implica que el logout le lleve al login del superadministrador
          localStorage.setItem("user-superadmin", true);

          changeLocale(
            Locale[data.user.locale]
              ? Locale[data.user.locale]
              : process.env.REACT_APP_DEFAULT_LOCALE_INTERFACE
          );
          setContentLanguage(data.user.locale);
          localStorage.setItem("locale", data.user.locale);
          changeLocale(Locale[data.user.locale]);

          dispatch({ type: GET_JWT_USER_DATA, payload: data.user });
          dispatch({ type: SET_JWT_TOKEN_SET, payload: data.token });
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: data.message });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

/*LOGOUT*/
export const onJWTAuthSignout = (token) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .get("users/logout")
      .then(({ data }) => {
        //Se hay realizado correctamente el logout o no eliminaremos las localstorage lo que provocará que el usuario tenga que loguearse de nuevo.
        localStorage.removeItem("jwt-auth-user");
        localStorage.removeItem("token-toptopa");
        localStorage.removeItem("locale");
        localStorage.removeItem("store");
        dispatch({ type: GET_STORE_DATA, payload: null });
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_JWT_USER_SUCCESS });
      })
      .catch(function (error) {
        localStorage.removeItem("jwt-auth-user");
        localStorage.removeItem("token-toptopa");
        localStorage.removeItem("locale");
        localStorage.removeItem("store");
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SIGNOUT_JWT_USER_SUCCESS });
        dispatch({ type: FETCH_ERROR, payload: error.response.status });
      });
  };
};

/*FORGOT PASSWORD*/
export const onForgotPassword = (email, locale, history) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .post("users/forgot", {
        email: email,
        locale: locale,
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          history.push("/signin");
        } else {
          if (data.errors) {
            if (data.errors.validate.email) {
              dispatch({
                type: FETCH_ERROR,
                payload: data.errors.validate.email,
              });
            }
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.response.status });
      });
  };
};

/*NEW PASSWORD*/
export const onGetNewPassword = (salt, hash, history) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .get(`users/recovery/${salt}/${hash}`)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: data.message,
          });
        }
      })
      .catch(function (error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onSetNewPassword = (
  newPassword,
  confirmPassword,
  locale,
  history,
  salt,
  hash
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "";
    jwtAxios
      .post(`users/recovery/${salt}/${hash}`, {
        password: newPassword,
        password2: confirmPassword,
        locale: locale,
      })
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({
            type: SHOW_MESSAGE,
            payload: data.message,
          });
          history.push("/signin");
        } else {
          if (data.errors) {
            if (data.errors.validate) {
              if (data.errors.validate.password) {
                dispatch({
                  type: FETCH_ERROR,
                  payload: data.errors.validate.password,
                });
              }
            }
          } else {
            dispatch({
              type: FETCH_ERROR,
              payload: data.message,
            });
          }
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.response.status });
      });
  };
};
