//API
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'show_message';
export const SHOW_MESSAGE_LOADING = 'show_message_loading';
export const HIDE_MESSAGE = 'hide_message';
export const TOGGLE_APP_DRAWER = 'toggle_app_drawer';
export const UPDATING_CONTENT = 'updating_content';

//CONFIG
export const GET_CONFIG = 'get_config';
export const GET_CATEGORIES = 'get_categories';

//STORE
export const GET_STORE_DATA = 'get_store_data';

//PRODUCT
export const GET_PRODUCT_DATA = 'get_product_data';

//APP SETTING
export const TOGGLE_NAV_COLLAPSED = 'toggle_nav_collapsed';
export const SET_INITIAL_PATH = 'set_initial_path';

//JWT_AUTH
export const UPDATE_JWT_AUTH_USER = 'update_jwt_auth_user';
export const UPDATE_JWT_AUTH_TOKEN = 'update_jwt_auth_token';
export const SIGNOUT_JWT_USER_SUCCESS = 'signout_jwt_user_success';
export const SET_JWT_TOKEN_SET = 'set_jwt_token_set';
export const GET_JWT_USER_DATA = 'get_jwt_user_data';
export const GET_USER_COMPLETE_DATA = 'get_user_complete_data';

export const SET_LOCALE = 'set_locale';


//FORMS
export const SET_IS_EDIT_FORM = 'SET_IS_EDIT_FORM';

//DIALOG
export const SET_SHOW_DIALOG_CHANGE_PAGE = 'set_show_dialog_change_page';
export const SET_SHOW_DIALOG_DELETE = 'set_show_dialog_delete';
export const SET_SHOW_DIALOG_ATTRIBUTES = 'set_show_dialog_attributes';
export const SET_SHOW_DIALOG_PRODUCT_ATTRIBUTE = 'set_show_dialog_product_attribute';
export const SET_SHOW_DIALOG_OK  = 'set_show_dialog_ok';
export const SET_SHOW_DIALOG_CONFIRM  = 'set_show_dialog_confirm';

//URL
export const SET_FUTURE_URL = 'SET_FUTURE_URL';