import React from "react";
import { useField } from "formik";
import {
  TextField,
  Checkbox,
  Select,
  FormHelperText,
  InputLabel,
  Switch,
  FormControlLabel
} from "@material-ui/core/";
// import { SortDirection } from "react-virtualized";

const MyTextField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <div style={{width:"100%"}}>
      <TextField
        {...props}
        {...field}
        helperText={errorText}
        error={!!errorText}
      />
      {props.sublabel ? <label style={{fontSize:12,fontStyle:"italic"}}>{props.sublabel}</label> : null}
      </div>
    </>
  );
};

const MyTextFieldOnChange = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  /*Llamada a la función de cambio del padre*/
  function handleChange(value) {
    if (props.onChange) {
      props.onChange(value);
    }
  }
  return (
    <>
      <TextField
        {...props}
        {...field}
        helperText={errorText}
        error={!!errorText}
        onChange={(event) => {
          handleChange(event.target.value);
        }}
      />
    </>
  );
};

const MyCheckboxField = (props) => {
  const [field] = useField(props);

  /*Llamada a la función de cambio del padre*/
  function handleChange(value, index) {
    if (props.onChange) {
      props.onChange(value, index);
    }
  }

  return (
    <Checkbox
      {...props}
      {...field}
      onChange={(event) => {
        handleChange(event.target.checked, props.index);
      }}
    />
  );
};

const MySelectFieldOnChange = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";

  /*Llamada a la función de cambio del padre*/
  function handleChange(value, index) {
    if (props.onChange) {
      props.onChange(value, index);
    }
  }

  return (
    <>
      <InputLabel shrink error={!!errorText}>
        {props.label}
      </InputLabel>
      <Select
        {...props}
        {...field}
        error={!!errorText}
        onChange={(event) => {
          handleChange(event.target.value, props.index);
        }}
      >
        {props.children}
      </Select>
      {errorText && (
        <FormHelperText style={{marginLeft:1}}error={!!errorText}>{errorText}</FormHelperText>
      )}
    </>
  );
};

const MySelectField = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <InputLabel shrink error={!!errorText}>
        {props.label}
      </InputLabel>
      <Select
        {...props}
        {...field}
        error={!!errorText}
      >
        {props.children}
      </Select>
      {errorText && (
        <FormHelperText style={{marginLeft:1}}error={!!errorText}>{errorText}</FormHelperText>
      )}
    </>
  );
};

const MySwitchField = (props) => {
  const [field] = useField(props);
  return (
    <FormControlLabel control={
      <Switch
        {...props}
        {...field}
      />}
    label={props.label}/>
  );
};

const MySwitchFieldOnChange = (props) => {
  const [field] = useField(props);

  /*Llamada a la función de cambio del padre*/
  function handleChange(value) {
    if (props.onChange) {
      props.onChange(value);
    }
  }

  return (
    <FormControlLabel control={
      <Switch
        {...props}
        {...field}
        onChange={(event) => {
          //Pasamos a la función el evento y el idioma que estamos modificando
          handleChange(event.target.checked);
        }}
      />}
    label={props.label}/>
  );
};

const MyTextFieldMultiLanguage = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <TextField
        fullWidth
        {...props}
        {...field}
        style={{
          display: props.display,
        }}
      />
      {errorText && (
        <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
      )}
    </>
  );
};

const MyTextAreaFieldMultiLanguage = (props) => {
  const [field, meta] = useField(props);
  const errorText = meta.error && meta.touched ? meta.error : "";
  return (
    <>
      <TextField
        {...props}
        {...field}
        style={{
          display: props.display,
        }}
      />
      {errorText && (
        <FormHelperText error={!!errorText}>{errorText}</FormHelperText>
      )}
    </>
  );
};

export {
  MyTextField,
  MyTextFieldOnChange,
  MyCheckboxField,
  MySelectFieldOnChange,
  MySelectField,
  MyTextFieldMultiLanguage,
  MyTextAreaFieldMultiLanguage,
  MySwitchField,
  MySwitchFieldOnChange
};