import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_PRODUCT_DATA,
  SHOW_MESSAGE,
} from "../../shared/constants/ActionTypes";

import {
  map,
  forEach,
  union,
  findWhere,
  sortBy,
  isEqual,
  isArray,
} from "underscore";

import { jwtAxios, InvalidToken } from "../../@crema/services/auth/";

import { defaultProductEmpty } from "../../../src/shared/constants/AppConst";

export const onGetProduct = (id, setproductData, taxesData, token) => {
  if (id) {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
      jwtAxios
        .get(`products/${id}`)
        .then(({ data }) => {
          dispatch({ type: FETCH_SUCCESS });

          //Mapear datos para marcar si el precio se ha modificado
          data.product.product_attributes = map(
            data.product.product_attributes,
            (product_attribute) => {
              if (!product_attribute.store_price) {
                product_attribute.price_changed = false;
              } else {
                product_attribute.price_changed =
                  product_attribute.store_price !== data.product.store_price
                    ? true
                    : false;
              }

              if (!product_attribute.store_weight) {
                product_attribute.changed_weight = false;
              } else {
                product_attribute.changed_weight =
                  product_attribute.store_weight !== data.product.store_weight
                    ? true
                    : false;
              }

              const iva = findWhere(taxesData, {
                id: data.product.store_tax_id,
              });

              product_attribute.iva_price = parseFloat(
                product_attribute.store_price +
                  (parseInt(iva.value) * product_attribute.store_price) / 100
              ).toFixed(3);
              if (product_attribute.store_price_offer > 0) {
                product_attribute.iva_store_price_offer = parseFloat(
                  product_attribute.store_price_offer +
                    (parseInt(iva.value) *
                      product_attribute.store_price_offer) /
                      100
                ).toFixed(3);
              }

              return product_attribute;
            }
          );

          //Mapear datos para extraer ids de características
          let features = [];
          forEach(data.product.features, (feature) => {
            features.push(feature.id);
          });
          data.product.features = features;

          //Mapear precio del producto
          data.product.store_price = parseFloat(
            data.product.store_price
          ).toFixed(3);
          data.product.store_price_offer = data.product.store_price_offer
            ? parseFloat(data.product.store_price_offer).toFixed(3)
            : "";

          setproductData(data.product);
        })
        .catch(function (error) {
          InvalidToken(error, dispatch);
        });
    };
  } else {
    return (dispatch) => {
      dispatch({
        type: GET_PRODUCT_DATA,
        payload: { data: defaultProductEmpty },
      });
    };
  }
};

export const onCreateUpdateProduct = (
  data_product,
  onSetIsEditForm,
  history,
  token
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    //Si no llega id es una creación, si llega una actualización
    let redirect = false;
    if (!data_product.id) {
      redirect = true;
    }
    const requestProduct = !data_product.id
      ? jwtAxios.post(`products/`, data_product)
      : jwtAxios.patch(`products/${data_product.id}`, data_product);
    requestProduct
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        if (redirect) {
          //Redirigir a la edición del producto cuando se trara de una creación
          setTimeout(() => {
            history.push("/admin/product/" + data.product.id);
          }, 1000);
        }
        dispatch(onSetIsEditForm(false));
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};

export const onDeleteProduct = (id, history, token) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .delete(`products/${id}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch({ type: SHOW_MESSAGE, payload: data.message });
        setTimeout(() => {
          history.push("/admin/products/list");
        }, 1000);
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};

export const onGetCombinations = (
  ids,
  setSelectedCombinations,
  product_attributes
) => {
  if (ids.length > 0) {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      jwtAxios
        .post("attributes/prepare", { ids })
        .then(({ data }) => {
          //Mapear las combinaciones para indicar que todas estárán seleccionadas
          data.combinations = map(data.combinations, (combination) => {
            let exist = false;

            if (!isArray(combination.ids)) {
              combination.ids = [combination.ids];
            }

            //Comprobar si la combinación ya había sido seleccionada previamente
            forEach(product_attributes, (attribute) => {
              let ids = [];
              forEach(attribute.attributes, (attribute) => {
                ids.push(attribute.id);
              });

              //Ordenar arrays para compararlos
              ids = sortBy(ids, function (id) {
                return id;
              });

              combination.ids = sortBy(combination.ids, function (id) {
                return id;
              });

              if (isEqual(ids, combination.ids)) {
                exist = true;
              }
            });

            combination.exist = exist;
            combination.selected = exist ? false : true;
            return combination;
          });
          setSelectedCombinations(data);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function (error) {
          InvalidToken(error, dispatch);
          //dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    };
  }
};

export const onCreateProductAttributes = (
  selected_combinations,
  setFieldValue,
  product_attributes,
  onSetShowDialogConfirm,
  setSelectedAttributes,
  setSelectedCombinations
) => {
  if (selected_combinations.combinations.length > 0) {
    return (dispatch) => {
      dispatch({ type: FETCH_START });
      jwtAxios
        .post("attributes/combine", selected_combinations)
        .then(({ data }) => {
          //Mapear datos para presentarlos en la tabla
          data.product_attributes = map(
            data.product_attributes,
            (attribute) => {
              attribute.price_changed =
                attribute.store_price > 0 ? true : false; //Para establecer permitir modificar el precio de la combinación
              attribute.price_offer =
                attribute.store_price_offer > 0 ? true : false; //Para establecer permitir crear un precio en oferta
              attribute.quantity = 1;
              attribute.spent = attribute.quantity === 0 ? true : false;
              return attribute;
            }
          );

          //Combinar combinaciones ya seleccionadas con las nuevas
          setFieldValue(
            "product_data.product_attributes",
            union(product_attributes, data.product_attributes)
          );

          //Limpiar combinaciones seleccionadas
          setSelectedAttributes([]);
          setSelectedCombinations([]);

          dispatch(onSetShowDialogConfirm(true));

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function (error) {
          InvalidToken(error, dispatch);
          //dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    };
  }
};

export const onGetProducts = (setDataProducts, filter, token, taxesData) => {
  let query = "";
  if (filter.page) {
    query += `page=${filter.page}&`;
  }
  if (filter.direction) {
    query += `direction=${filter.direction}&`;
  }
  if (filter.sort) {
    query += `sort=${filter.sort}&`;
  }
  if (filter.q) {
    query += `q=${filter.q}&`;
  }
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .get(`products/?${query}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        data.products = map(data.products, (product) => {
          const iva_object = findWhere(taxesData, {
            id: product.store_tax_id,
          });
          product.iva = iva_object.value;
          product.iva_price = parseFloat(
            parseFloat(product.store_price) +
              (parseFloat(product.store_price) * iva_object.value) / 100
          ).toFixed(3);
          if (product.store_price_offer) {
            product.iva_store_price_offer = parseFloat(
              parseFloat(product.store_price_offer) +
                (parseFloat(product.store_price_offer) * iva_object.value) / 100
            ).toFixed(3);
          }
          return product;
        });
        setDataProducts(data);
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
        //dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};
