import React from "react";
import { Formik, Form } from "formik";
import {
  MySwitchField,
  MyTextFieldOnChange,
  MySwitchFieldOnChange
} from "../../../../@crema/core/Formik/";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  DialogTitle,
} from "@material-ui/core/";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import GridContainer from "@crema/core/GridContainer";
import { onSetShowDialogProductAttribute } from "../../../../redux/actions";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

/*IDIOMAS*/
import IntlMessages from "../../../../@crema/utility/IntlMessages";
import { useIntl } from "react-intl";
/*FIN IDIOMAS*/

export default function ModalFormProductAttribute({
  product_attribute, //Atributo seleccionado
  product_data, //Datos del producto
  setFieldValue, //Para poder asignar el atributo
  iva,
}) {
  const show_dialog_product_attribute = useSelector(
    ({ forms }) => forms.show_dialog_product_attribute
  );

  const dispatch = useDispatch();

  function handleClose() {
    dispatch(onSetShowDialogProductAttribute(false));
  }

  function handleCancel() {
    dispatch(onSetShowDialogProductAttribute(false));
  }

  /*ESTILOS PERSONALIZADOS DEL COMPONENTE */
  const useStyles = makeStyles((theme) => {
    return {
      title: {
        textTransform: "uppercase",
        marginBottom: 15,
      },
      attributes: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
      },
      dialogPaper: {
        minHeight: "80vh",
        minWidth: "calc(100vw - 40px)",
      },
      attribute: {
        display: "flex",
        alignItems: "center",
        marginRight: 60,
        "& label": {
          minWidth: 180,
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 0,
        },
      },
      color: {
        backgroundColor: "#ccc",
        height: 15,
        width: 15,
        marginRight: 10,
      },
      closeButton: {
        position: "absolute",
        right: theme.spacing(0.5), // theme.spacing.unit / 2,
        top: theme.spacing(0.5), // theme.spacing.unit / 2,
        color: theme.palette.grey[500],
      },
      selected: {
        padding: 9,
        color: "#f44336",
      },
      row: {
        display: "flex",
        flexDirection: "column",
        alignItems: "left",
        width: "100%",
        paddingLeft: 10,
        marginBottom: 20,
      },
      textField: {
        marginTop: 15,
        marginBottom: 5,
        paddingBottom: 5,
      },
      store_price_offer: {
        marginBottom: 5, 
        marginTop: 10, 
        "& input": {
          color: "#F4445A",
        },
      },
    };
  });
  /*FIN ESTILOS PERSONALIZADOS DEL COMPONENTE */

  const theme = useTheme();
  const classes = useStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { messages } = useIntl();

  const inputProps = {
    step: "any",
  };

  const inputPropsOffer = {
    step: "any",
    color: "#F4445A",
  };

  return show_dialog_product_attribute && product_attribute ? (
    <>
      <Dialog
        open={show_dialog_product_attribute}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        classes={{ paper: classes.dialogPaper }}
      >
        <Formik
          initialValues={{
            product_attribute: product_attribute,
            product_data: product_data,
          }}
          onSubmit={(values) => {
            setFieldValue(
              `product_data.product_attributes.${product_attribute.tableData.id}`,
              values.product_attribute
            );
            dispatch(onSetShowDialogProductAttribute(false));
          }}
        >
          {({ values, initialValues, setFieldValue, errors, touched }) => {
            return (
              <Form>
                <DialogTitle id="alert-dialog-title">
                  <IntlMessages id="product.combination" /> :{" "}
                  {product_attribute.attributes_title}
                </DialogTitle>
                <DialogContent>
                  <GridContainer>
                    <div className={classes.row}>
                      <MySwitchField
                        name="product_attribute.published"
                        checked={values.product_attribute.published}
                        color="secondary"
                        label={messages["product.published"]}
                      />
                    </div>
                    <div className={classes.row}>
                      <MySwitchField
                        name="product_attribute.price_changed"
                        checked={values.product_attribute.price_changed}
                        color="secondary"
                        label={messages["product.changed_price"]}
                      />

                      {values.product_attribute.price_changed && (
                        <>
                          <MyTextFieldOnChange
                            style={{ marginBottom: 5, marginTop: 10 }}
                            name={"product_attribute.iva_price"}
                            label={
                              messages[
                                "product.combinations.store_price"
                              ] +
                              " " +
                              messages["product.combinations.store_price_iva"]
                            }
                            type="number"
                            inputProps={inputProps}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.iva_price",
                                value
                              );
                              if (iva) {
                                setFieldValue(
                                  "product_attribute.store_price",
                                  parseFloat(
                                    parseFloat(value * 100) /
                                      (100 + parseInt(iva))
                                  ).toFixed(3)
                                );
                              }
                            }}
                          />
                          <MyTextFieldOnChange
                            style={{ marginBottom: 5, marginTop: 10 }}
                            name={"product_attribute.store_price"}
                            label={
                              messages["product.combinations.store_price"] +
                              " " +
                              messages["product.combinations.price_no_iva"]
                            }
                            type="number"
                            inputProps={inputProps}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.store_price",
                                value
                              );
                              if (iva) {
                                setFieldValue(
                                  "product_attribute.iva_price",
                                  parseFloat(
                                    parseFloat(value) +
                                      (parseFloat(value) * parseInt(iva)) / 100
                                  ).toFixed(2)
                                );
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className={classes.row}>
                      <MySwitchField
                        name="product_attribute.price_offer"
                        checked={values.product_attribute.price_offer}
                        color="secondary"
                        label={messages["product.create_offer"]}
                      />

                      {values.product_attribute.price_offer && (
                        <>
                          <MyTextFieldOnChange
                            className={classes.store_price_offer}
                            name={"product_attribute.iva_store_price_offer"}
                            label={
                              messages[
                                "product.combinations.store_price_offer_1"
                              ] +
                              " " +
                              messages[
                                "product.combinations.store_price_iva"
                              ]
                            }
                            type="number"
                            inputProps={inputPropsOffer}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.iva_store_price_offer",
                                value
                              );
                              if (iva) {
                                setFieldValue(
                                  "product_attribute.store_price_offer",
                                  parseFloat(
                                    parseFloat(value * 100) /
                                      (100 + parseInt(iva))
                                  ).toFixed(3)
                                );
                              }
                            }}
                          />
                          <MyTextFieldOnChange
                            className={classes.store_price_offer}
                            name={"product_attribute.store_price_offer"}
                            label={
                              messages[
                                "product.combinations.store_price_offer_1"
                              ] +
                              " " +
                              messages[
                                "product.combinations.price_no_iva"
                              ]
                            }
                            type="number"
                            inputProps={inputPropsOffer}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.store_price_offer",
                                value
                              );
                              if (iva) {
                                setFieldValue(
                                  "product_attribute.iva_store_price_offer",
                                  parseFloat(
                                    parseFloat(value) +
                                      (parseFloat(value) * parseInt(iva)) / 100
                                  ).toFixed(2)
                                );
                              }
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className={classes.row}>
                      <MySwitchField
                        name="product_attribute.changed_weight"
                        checked={values.product_attribute.changed_weight}
                        color="secondary"
                        label={messages["product.changed_weight"]}
                      />
                      {values.product_attribute.changed_weight && (
                        <>
                          <MyTextFieldOnChange
                            style={{ marginBottom: 5, marginTop: 10 }}
                            name={"product_attribute.store_weight"}
                            label={
                              messages["general_data.weight_1"] +
                              " " +
                              messages["general_data.weight_2"]
                            }
                            type="number"
                            inputProps={inputProps}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.store_weight",
                                value
                              );
                            }}
                          />
                        </>
                      )}
                    </div>
                    <div className={classes.row}>

                      <MySwitchFieldOnChange
                        name="product_attribute.spent"
                        checked={values.product_attribute.spent}
                        color="secondary"
                        label={messages["product.agot"]}
                        onChange={(value) => {
                          setFieldValue("product_attribute.spent", value);
                          if (value) {
                            setFieldValue("product_attribute.quantity", 0);
                          }
                        }}
                      />

                      {!values.product_attribute.spent && (
                        <>
                          <MyTextFieldOnChange
                            style={{ marginBottom: 5, marginTop: 10 }}
                            name={"product_attribute.quantity"}
                            label={messages["product.stock"]}
                            type="number"
                            inputProps={inputProps}
                            fullWidth
                            onChange={(value) => {
                              setFieldValue(
                                "product_attribute.quantity",
                                value
                              );
                            }}
                          />
                        </>
                      )}
                    </div>
                  </GridContainer>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={handleCancel}
                    color="primary"
                    variant="contained"
                  >
                    <IntlMessages id="app.cancel" />
                  </Button>
                  <Button type="submit" color="secondary" variant="contained">
                    <IntlMessages id="app.save" />
                  </Button>
                </DialogActions>
                <IconButton
                  aria-label="Close"
                  className={classes.closeButton}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </IconButton>
              </Form>
            );
          }}
        </Formik>
      </Dialog>
    </>
  ) : null;
}