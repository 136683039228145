import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {useDispatch,useSelector} from 'react-redux';
import IntlMessages from '../../../../@crema/utility/IntlMessages';
import {onSetShowDialogConfirm} from '../../../../redux/actions';

export default function AlertFormConfirm({title,message,function_close}) {
  const show_dialog_confirm = useSelector(({forms}) => forms.show_dialog_confirm);
  const dispatch = useDispatch();
  function handleClose() {
    dispatch(onSetShowDialogConfirm(false));
    dispatch(function_close(false));
  }
  return (
    <div>
      <Dialog
        open={show_dialog_confirm}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <IntlMessages id={title}/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <IntlMessages id={message} />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary' autoFocus variant="contained">
            <IntlMessages id='app.accept' />
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
