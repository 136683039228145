import React, { useContext } from "react";
import clsx from "clsx";
import { map } from "underscore";
import AppContext from "../../../../@crema/utility/AppContext";
import List from "@material-ui/core/List";
import Box from "@material-ui/core/Box";
import ListItem from "@material-ui/core/ListItem";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import routesConfig from "../../../../modules/routesConfig";
import VerticalCollapse from "./VerticalCollapse";
import VerticalItem from "./VerticalItem";
import VerticalNavGroup from "./VerticalNavGroup";
import VerticalItemOnClick from "./VerticalItemOnClick";
import Divider from "@material-ui/core/Divider";
import { makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { onJWTAuthSignout, onUpdateUser } from "../../../../redux/actions";
import IntlMessages from "../../../../@crema/utility/IntlMessages";

const useStyles = makeStyles((theme) => ({
  divider: {
    marginTop: 30,
  },
  language: {
    color: theme.palette.primary.main,
    paddingTop: 0,
    marginRight: 20,
    textTransform: "uppercase",
    width: "100%",
    "& svg": {
      color: theme.palette.primary.main,
    },
  },
  icon: {
    fontFamily: "toptopa-icons",
    color: "#404040",
    fontSize: "1.2rem",
  },
  navItem: {
    paddingLeft: 16,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  envio:{
    paddingLeft:68, marginTop:10, display:"block", textDecoration:"none", fontSize:16, color:"#1DBDBD", fontWeight:700,
    [theme.breakpoints.down("lg")]: {
      paddingLeft:60,
    },
  }
}));

const item_help = {
  id: "help",
  title: "Help",
  messageId: "sidebar.help",
  icon: "icon-ayuda icon-menu",
  url: "/help",
};

const item_close = {
  id: "close",
  title: "Close",
  messageId: "sidebar.close",
  icon: "icon-cerrar",
};

const Navigation = () => {
  const dispatch = useDispatch();

  const handleClose = (token) => {
    dispatch(onJWTAuthSignout(token));
  };

  const handleWeb = () => {
    if (storeData) {
      if (storeData.url) {
        window.open(storeData.url, "_blank");
      }
    }
  };

  const storeData = useSelector(({ store }) => store.storeData); //Datos de la tienda, para mostrar el logotipo si lo tuviera
  const userData = useSelector(({ jwtAuth }) => jwtAuth); //Datos del usuario

  let link = null;
  if (storeData) {
    if (storeData.url) {
      const item_web = {
        id: "web",
        title: "sidebar.app.view.web",
        messageId: "sidebar.app.view.web",
        icon: "icon-tiendas",
      };
      link = (
        <Box mt={5}>
          <VerticalItemOnClick
            item={item_web}
            level={0}
            handleClick={handleWeb}
          />
        </Box>
      );
    }
  }

  const { contentLanguage, setContentLanguage, changeLocale } = useContext(
    AppContext
  );
  const configData = useSelector(({ config }) => config.configData); //Datos para la carga de idiomas
  const languages = configData ? configData.languages : null;
  const handleChangeLanguage = (e) => {
    const user = {
      id: userData.user.id,
      locale: e.target.value,
    };
    dispatch(onUpdateUser(user, setContentLanguage, changeLocale, userData.token));
  };

  const classes = useStyles();

  return (
    <List>
      {link}
      {routesConfig.map((item) => (
        <React.Fragment key={item.id}>
          {item.type === "group" && <VerticalNavGroup item={item} level={0} />}
          {item.type === "collapse" && (
            <VerticalCollapse item={item} level={0} storeData={storeData} />
          )}
          {item.type === "item" && <VerticalItem item={item} level={0} />}
        </React.Fragment>
      ))}
      <Divider className={classes.divider} />
      <Box mt={5}>
        {languages && (
          <ListItem className={clsx(classes.navItem, "nav-item")}>
            <Box component="span" mr={6}>
              <div className={classes.icon}>
                <div className="icon-idiomas"></div>
              </div>
            </Box>
            <Select
              className={classes.language}
              value={contentLanguage}
              onChange={handleChangeLanguage}
            >
              {map(languages, (value) => {
                return (
                  <MenuItem key={value.id} value={value.iso3} name={value.id}>
                    {value.name.toUpperCase()}
                  </MenuItem>
                );
              })}
            </Select>
          </ListItem>
        )}

        <VerticalItemOnClick
          item={item_close}
          level={0}
          handleClick={()=>handleClose(userData.token)}
        />
        {/* <VerticalItem item={item_help} level={0} /> */}

      </Box>
    </List>
  );
};

export default Navigation;