import React, { useState, useEffect, useContext, Fragment } from "react";
import Media from "react-media";
import AppContext from "../../../@crema/utility/AppContext";
import {
	map,
	each,
	isEqual,
	findWhere,
	contains,
	without,
	forEach,
} from "underscore";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MaterialTable from "material-table";

/* COMPONENTES */
import ComponentHeader from "@crema/core/ComponentHeader";
import GridContainer from "@crema/core/GridContainer";
import { InfoView } from "../../../@crema";
import InputMultiLanguageMyTextField from "../../../@crema/core/InputMultiLanguage/MyTextField";
import InputMultiLanguageMyTextAreaField from "../../../@crema/core/InputMultiLanguage/MyTextAreaField";
import AlertFormDelete from "../../../../src/modules/muiComponents/feedback/Dialogs/AlertFormDelete";
import ModalFormAttributes from "../../../../src/modules/muiComponents/feedback/Dialogs/ModalFormAttributes";
import ModalFormProductAttribute from "../../../../src/modules/muiComponents/feedback/Dialogs/ModalFormProductAttribute";
import ImageUploading from "react-images-uploading";
import { tableCombinations } from "../../../../src/shared/constants/AppConst";
/* FIN COMPONENTES */

/* MATERIAL UI */
import { withStyles } from "@material-ui/core/styles";
import makeStyles from "./style";
import {
	Button,
	MenuItem,
	Card,
	CardContent,
	CardMedia,
	FormControlLabel,
	Avatar,
	Box,
	Checkbox,
	Grid,
	TextField,
	Switch,
	Tooltip,
	FormHelperText,
} from "@material-ui/core/";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import DeleteIcon from "@material-ui/icons/Delete";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
/*FIN MATERIAL UI*/

/*IDIOMAS*/
import IntlMessages from "../../../@crema/utility/IntlMessages";
import { useIntl } from "react-intl";
/*FIN IDIOMAS*/

/*FORMULARIO*/
import { Formik, Form, FieldArray } from "formik";
import {
	MyTextField,
	MySelectFieldOnChange,
	MyCheckboxField,
	MySwitchField,
	MySwitchFieldOnChange,
	MyTextFieldOnChange,
} from "../../../@crema/core/Formik/";
import * as yup from "yup";
/*FIN FORMULARIO*/

/*DATOS Y ACCIONES*/
import { onGetBrands } from "../../../redux/actions/Brands";
import { onUploadPhotos } from "../../../redux/actions/Store";
import { onGetProduct } from "../../../redux/actions/Product";
import {
	onSetIsEditForm,
	onSetShowDialogDelete,
	onSetShowDialogAttributes,
	onSetShowDialogProductAttribute,
	onCreateUpdateProduct,
	onDeleteProduct,
	onGetStore,
	onGetCategory,
} from "../../../redux/actions";
import { defaultProductEmpty } from "../../../../src/shared/constants/AppConst";
/*FIN DATOS Y ACCIONES */

/*ENUMERACIONES*/
import { Fonts } from "../../../shared/constants/AppEnums";
/*FIN ENUMERACIONES*/

/*ESTILOS PERSONALIZADOS DEL COMPONENTE */
const useStyles = makeStyles;
/*FIN ESTILOS PERSONALIZADOS DEL COMPONENTE */

let total_price = ""; //Hacer cálculo precio
let total_price_offer = ""; //Hacer cálculo precio oferta

//Función para eliminar los atributos del producto
const deleteAttributes = (setFieldValue) => {
	setFieldValue("product_data.product_attributes", []);
	setFieldValue("product_data.combined", false);
};

//Función para cambiar la categoría del producto y eliminar los atributos del producto
const updateCategory = (parameters) => {
	//setFieldValue --> parameters[0]
	//index --> parameters[1]
	//value --> parameters[2]
	//category_levels --> parameters[3]
	//arrayHelpers --> parameters[4]
	//dispatch --> parameters[5]
	//setCategory --> parameters[6]
	//setDataBrands --> parameters[7]
	//userData.token --> parameters[8]

	// Actualizo las marcas si es el primer nivel
	if (parameters[1] == 0) {
		parameters[0]("product_data.store_brand_id", "");
		parameters[5](onGetBrands(parameters[7], parameters[2], parameters[8]));
	}

	//Cambio de categoría
	parameters[0](`product_data.category_levels.${parameters[1]}`, parameters[2]);
	for (var i = parameters[1] + 1; i < parameters[3].length; i++) {
		parameters[4].replace(i, "-");
	}

	//Eliminar atributos creados
	parameters[0]("product_data.product_attributes", []);

	//Eliminar características creadas
	parameters[0]("product_data.features", []);

	//Eliminar formatos
	parameters[0]("product_data.format_id", "");
	parameters[0]("product_data.format_value", "");

	//Cargar los nuevos datos de la categoría
	parameters[5](onGetCategory(parameters[2], parameters[6], parameters[0]));
};

/*FIN FUNCIONES*/

const Product = (props) => {
	/* ESTADOS */

	//Para indicar que funcion y que identificador tiene que borrarse en el dialog de confirmación
	const [dialog_delete, setDialogDelete] = useState({
		action: "",
		index: "",
		title: "",
		message: "",
	});

	//Para indicar que funcion y que identificador tiene que borrarse en el dialog de confirmación
	const [dialog_attributes, setDialogAttributes] = useState({
		group_attributes: [],
		product_attributes: [],
		setFieldValue: null,
	});

	//Para poder editar un atributo de producto
	const [dialog_product_attribute, setDialogProductAttribute] = useState({
		product_attribute: {},
		product_data: {},
		setFieldValue: null,
		iva: null,
	});

	// Datos de las marcas
	const [dataBrands, setDataBrands] = useState([]);
	const [dataBrand, setDataBrand] = useState(null);

	// Datos de la categoría
	const [category, setCategory] = useState(null);

	//Control del ordenado
	const [iva, setIva] = useState(21);

	/* FIN ESTADOS */

	/*DATOS*/

	//Obtener los datos del usuario
	const history = useHistory();

	//Obtener los datos del producto, si no hay una idea se obtendrá un objeto de producto vacio
	const [productData, setproductData] = useState(null);

	//Obtener los datos de configuración y categorías
	const configData = useSelector(({ config }) => config.configData);
	const categoriesData = useSelector(({ config }) => config.categoriesData);

	//Obtener datos de impuesto de la configuración
	const taxesData = configData ? configData.taxes : null;

	//Obtener los datos del usuario
	const userData = useSelector(({ jwtAuth }) => jwtAuth);

	const dispatch = useDispatch();
	useEffect(() => {
		//Pedir datos de tienda
		if (userData.user && userData.token) {
			dispatch(onGetStore(userData.token, false, true));
		}
	}, [dispatch]);

	//Obtener datos de tienda
	const storeData = useSelector(({ store }) => {
		if (typeof store.storeData === "string") {
			return JSON.parse(store.storeData);
		}

		return store.storeData;
	});

	useEffect(() => {
		if (storeData) {
			//Si la tienda solo tiene una categoría cuando se va a crear un producto nuevo se seleccionará esta.
			let product_empty = defaultProductEmpty;
			if (storeData.category_ids.length === 1 && !props.match.params.id) {
				product_empty.category_levels = storeData.category_ids;
			}
			//Cargar un producto vacio si no se le pasa id de producto a la url, CREACIÓN DE UN PRODUCTO
			if (!props.match.params.id) {
				setproductData(product_empty); //product_empty tiene todos los datos que debería de tener un producto
			} else {
				//Obtener datos de un producto que ya existe
				if (userData.user && userData.token) {
					dispatch(
						onGetProduct(
							props.match.params.id,
							setproductData,
							taxesData,
							userData.token
						)
					);
				}
			}
		}
	}, [storeData, taxesData]);

	//Obtener datos de la categoría
	useEffect(() => {
		if (productData) {
			// Marcas de la categoría
			if (productData.category_levels[0]) {
				dispatch(
					onGetBrands(
						setDataBrands,
						productData.category_levels[0],
						userData.token
					)
				);
			}

			if (productData.category_id) {
				dispatch(onGetCategory(productData.category_id, setCategory));
			}
			const iva_object = findWhere(taxesData, {
				id: productData.store_tax_id,
			});
			if (iva_object) {
				setIva(iva_object.value);
			}
		}
	}, [productData]);

	useEffect(() => {
		if (productData && productData) {
			dataBrands.find(_brand => {
				if (_brand.id == productData.store_brand_id) {
					setDataBrand(_brand);
				}
			})
		}
	}, [productData, dataBrands]);

	//Idiomas que serán los que se muestren en los inputs multi-idioma
	const languages = configData ? configData.languages : null;

	const inputProps = {
		step: "any",
	};

	/*FIN DATOS*/

	/*FUNCIÓN */
	//Función recursiva para mostrar las categorías
	const show_category = (
		categories,
		id,
		jsx,
		level,
		category_levels,
		classes,
		contentLanguage,
		arrayHelpers,
		setFieldValue,
		product_attributes,
		combined,
		product_data
	) => {
		let category = findWhere(categories, { id: id });
		//Imprimir todos los elementos y seleccionar el elemento
		jsx = (
			<>
				{jsx}
				<Grid key={0} item xs={12} sm={6} md={3} lg={3}>
					<MySelectFieldOnChange
						name={`product_data.category_levels.${level}`}
						fullWidth
						className={classes.select}
						index={level}
						onChange={(value, index) => {
							//Las combinaciones dependen de las categorías, así que si el producto es variable y la categoría se cambia
							//tiene que hacer dos cosas, pedir confirmación, y en el caso de que se diga que si se deberán de eliminar las combinaciones
							// y realizar una petición para obtener los nuevos attributes.
							if (
								userData &&
								(product_data.product_attributes.length > 0 ||
									product_data.features.length > 0 ||
									product_data.format_id !== "")
							) {
								//Aviso de que se actualizará la categoría y se limpiarán combinaciones, características y formato
								setDialogDelete({
									action: updateCategory,
									index: [
										setFieldValue,
										index,
										value,
										category_levels,
										arrayHelpers,
										dispatch,
										setCategory,
										setDataBrands,
										userData.token,
									],
									title: "product.category.change",
									message: "product.category.change.message",
								});
								dispatch(onSetShowDialogDelete(true));
							} else {
								if (index == 0) {
									dispatch(onGetBrands(setDataBrands, value, userData.token));
								}

								setFieldValue("product_data.category_id", value);
								setFieldValue(`product_data.category_levels.${index}`, value);
								for (var i = index + 1; i < category_levels.length; i++) {
									arrayHelpers.replace(i, "-");
								}
								dispatch(onGetCategory(value, setCategory, setFieldValue));
							}
						}}
					>
						{map(categories, (value) => {
							return (
								<MenuItem key={value.id} value={value.id} name={value.id}>
									{value._translations
										? value._translations[contentLanguage].title
										: value.title}
								</MenuItem>
							);
						})}
					</MySelectFieldOnChange>
				</Grid>
			</>
		);

		if (category) {
			if (category.children.length > 0) {
				var a = findWhere(category.children, {
					id: category_levels[level + 1],
				});
				return show_category(
					category.children,
					a ? category_levels[level + 1] : null,
					jsx,
					level + 1,
					category_levels,
					classes,
					contentLanguage,
					arrayHelpers,
					setFieldValue,
					product_attributes,
					combined,
					product_data
				);
			}
		}
		return jsx;
	};

	/*FUNCIONES*/
	//Función para el cambio de precio del producto
	const changePrice = (productData, taxesData) => {
		const iva_object = findWhere(taxesData, {
			id: productData.store_tax_id,
		});
		if (iva_object) {
			total_price = parseFloat(
				parseFloat(productData.store_price) +
				(parseInt(iva_object.value) * productData.store_price) / 100
			).toFixed(3);
		}
	};

	//Función para el cambio de precio del producto en oferta
	const changePriceOffer = (productData, taxesData) => {
		// setHasPriceOffer(true);
		const iva_object = findWhere(taxesData, {
			id: productData.store_tax_id,
		});
		if (iva_object && productData.store_price_offer) {
			total_price_offer = parseFloat(
				parseFloat(productData.store_price_offer) +
				(parseInt(iva_object.value) * productData.store_price_offer) / 100
			).toFixed(3);
		}
	};
	/*FIN FUNCIÓN */

	/*IDIOMA*/
	const { messages } = useIntl();
	const { contentLanguage } = useContext(AppContext);
	/*FIN IDIOMA*/

	/*FOTOS*/
	const maxFileSize = 1500000; //1.5MB
	const onChangePhotosImages = (
		imageList,
		addUpdateIndex,
		setFieldValue,
		values,
		arrayHelpers,
		locales
	) => {
		if (addUpdateIndex) {
			dispatch(
				onUploadPhotos(
					imageList,
					addUpdateIndex,
					"product",
					values,
					arrayHelpers,
					locales,
					userData.token
				)
			);
		} else {
			setFieldValue("product_data.photos", []);
		}
	};
	/*FIN FOTOS*/

	/*EVENTOS*/
	//Eliminación del producto
	const deleteProduct = (index) => {
		dispatch(onDeleteProduct(index, history, userData.token));
	};

	//Redirección al listado de productos
	const onGoToProductList = () => {
		history.push(`/admin/products/list/?p=${props.location.state.page}`);
	};

	/*FIN EVENTOS*/

	/*TABLA DE COMBINACIONES*/

	const renderCombinationName = (row) => {
		let title = row.attributes_title;
		let jsx = {};
		let color_attribute = null;
		forEach(row.attributes, (attribute) => {
			if (attribute.color) {
				color_attribute = attribute;
			}
		});
		if (color_attribute) {
			jsx = (
				<div className={classes.attribute}>
					<div
						className={classes.color}
						style={{ backgroundColor: color_attribute.color }}
					></div>
					<label>{title}</label>
				</div>
			);
		} else {
			jsx = (
				<div className={classes.attribute}>
					<label>{title}</label>
				</div>
			);
		}
		return jsx;
	};

	const renderPriceChanged = (row, product_data) => {
		return <Switch checked={row.price_changed} color="secondary" readOnly />;
	};

	const renderStorePrice = (row, product_data) => {
		let price =
			row.store_price > 0
				? parseFloat(row.store_price).toFixed(3)
				: parseFloat(product_data.store_price).toFixed(3);
		return <div>{price ? price + " €" : ""}</div>;
	};

	const renderStorePriceIVA = (row, product_data) => {
		let total_price = 0;
		total_price = product_data.store_price;
		total_price = parseFloat(
			parseFloat(total_price) + (parseFloat(total_price) * iva) / 100
		).toFixed(3);
		if (row.store_price > 0) {
			total_price = parseFloat(
				parseFloat(row.store_price) + (parseFloat(row.store_price) * iva) / 100
			).toFixed(3);
		}
		return <div>{total_price ? total_price + " €" : ""}</div>;
	};

	const renderCreateOffer = (row) => {
		if (row.store_price_offer) {
			row.price_offer = true;
		} else {
			row.price_offer = false;
		}
		return <Switch checked={row.price_offer} color="secondary" readOnly />;
	};

	const renderStorePriceOffer = (row, product_data) => {
		let total_price = 0;
		if (product_data.store_price_offer) {
			total_price = parseFloat(product_data.store_price_offer).toFixed(3);
		}
		if (row.store_price_offer > 0) {
			total_price = parseFloat(row.store_price_offer).toFixed(3);
		}
		if (total_price > 0) {
			return (
				<div style={{ color: "#F4445A" }}>
					{total_price ? total_price + " €" : ""}
				</div>
			);
		} else {
			return "";
		}
	};

	const renderStorePriceOfferIVA = (row, product_data) => {
		let total_price = 0;
		if (product_data.store_price_offer) {
			total_price = parseFloat(
				parseFloat(product_data.store_price_offer) +
				(parseFloat(product_data.store_price_offer) * iva) / 100
			).toFixed(3);
		}
		if (row.store_price_offer > 0) {
			total_price = parseFloat(
				parseFloat(row.store_price_offer) +
				(parseFloat(row.store_price_offer) * iva) / 100
			).toFixed(3);
		}
		if (total_price > 0) {
			return (
				<div style={{ color: "#F4445A" }}>
					{total_price ? total_price + " €" : ""}
				</div>
			);
		} else {
			return "";
		}
	};

	const renderStock = (row, product_data) => {
		return <div>{row.quantity}</div>;
	};

	const renderSpent = (row) => {
		if (row.quantity === 0) {
			row.spent = true;
		} else {
			row.spent = false;
		}
		if (row.spent) {
			row.quantity = 0;
		}
		return <Switch checked={row.spent} color="secondary" readOnly />;
	};

	const renderPublished = (row) => {
		return <Switch checked={row.published} color="secondary" readOnly />;
	};

	const renderWeightChanged = (row, product_data) => {
		if (row.store_weight) {
			row.weight_changed = true;
		} else {
			row.weight_changed = false;
		}
		return <Switch checked={row.weight_changed} color="secondary" readOnly />;
	};

	const renderWeight = (row, product_data) => {
		const original_store_weight = product_data.store_weight
			? product_data.store_weight
			: "";
		const weight =
			row.store_weight > 0 ? row.store_weight : original_store_weight;
		return <div>{weight ? weight : ""}</div>;
	};

	const makeColumns = (product_data) => {
		return [
			{
				title: messages["product.combination"],
				render: renderCombinationName,
				field: "attributes_title",
				editable: "never",
				cellStyle: (cellValue, rowData) => {
					return {
						width: "20%",
						maxWidth: "20%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
						textTransform: "uppercase",
					};
				},
				headerStyle: {
					width: "20%",
					maxWidth: "20%",
				},
			},
			{
				title: messages["product.changed_price"],
				render: (rowData) => renderPriceChanged(rowData, product_data),
				field: "price_changed",
				sorting: false,
				editComponent: (props) => {
					return (
						<Switch
							checked={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.price_changed = e.target.checked;
								if (!e.target.checked) {
									data.store_price = 0;
								}
								props.onRowDataChange(data);
							}}
						/>
					);
				},
				cellStyle: {
					width: "6%",
					maxWidth: "6%",
				},
				headerStyle: {
					width: "6%",
					maxWidth: "6%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.combinations.store_price"]}</div>
						<small style={{ fontWeight: 100, textTransform: "none" }}>
							{messages["product.combinations.store_price_iva"]}
						</small>
					</div>
				),
				field: "iva_price",
				editable: (cellValue, rowData) =>
					rowData.price_changed ? "always" : "never",
				render: (rowData) => renderStorePriceIVA(rowData, product_data),
				editComponent: (props) => {
					return props.rowData.price_changed ? (
						<TextField
							value={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.iva_price = e.target.value;
								if (iva) {
									data.store_price = parseFloat(
										parseFloat(e.target.value * 100) / (100 + parseInt(iva))
									).toFixed(3);
								}
								props.onRowDataChange(data);
							}}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				cellStyle: (cellValue, rowData) => {
					return {
						width: "5%",
						maxWidth: "5%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				headerStyle: {
					width: "5%",
					maxWidth: "5%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.combinations.store_price"]}</div>
						<small style={{ fontWeight: 100, textTransform: "none" }}>
							{messages["product.combinations.price_no_iva"]}
						</small>
					</div>
				),
				field: "store_price",
				editable: (cellValue, rowData) =>
					rowData.price_changed ? "always" : "never",
				render: (rowData) => renderStorePrice(rowData, product_data),
				editComponent: (props) => {
					return props.rowData.price_changed ? (
						<TextField
							value={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.store_price = e.target.value;
								if (iva) {
									data.iva_price = parseFloat(
										parseFloat(e.target.value) +
										(parseFloat(e.target.value) * parseInt(iva)) / 100
									).toFixed(3);
								}
								props.onRowDataChange(data);
							}}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				type: "currency",
				currencySetting: {
					locale: "es-ES",
					currencyCode: "EUR",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				},
				validate: (rowData) =>
					!rowData.price_changed || rowData.store_price > 0,
				cellStyle: (cellValue, rowData) => {
					return {
						width: "5%",
						maxWidth: "5%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				headerStyle: {
					width: "5%",
					maxWidth: "5%",
				},
			},
			{
				title: messages["product.create_offer"],
				render: renderCreateOffer,
				field: "price_offer",
				sorting: false,
				editComponent: (props) => {
					return (
						<Switch
							checked={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.price_offer = e.target.checked;
								if (!e.target.checked) {
									data.store_price_offer = "";
								}
								props.onRowDataChange(data);
							}}
						/>
					);
				},
				cellStyle: {
					width: "6%",
					maxWidth: "6%",
				},
				headerStyle: {
					width: "6%",
					maxWidth: "6%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.combinations.store_price_offer_1"]}</div>
						<small style={{ fontWeight: 100, textTransform: "none" }}>
							{messages["product.combinations.store_price_iva"]}
						</small>
					</div>
				),
				field: "iva_store_price_offer",
				editable: (cellValue, rowData) =>
					rowData.price_offer ? "always" : "never",
				render: (rowData) => renderStorePriceOfferIVA(rowData, product_data),
				editComponent: (props) => {
					return props.rowData.price_offer ? (
						<TextField
							value={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.iva_store_price_offer = e.target.value;
								if (iva) {
									data.store_price_offer = parseFloat(
										parseFloat(e.target.value * 100) / (100 + parseInt(iva))
									).toFixed(3);
								}
								props.onRowDataChange(data);
							}}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				type: "currency",
				currencySetting: {
					locale: "es-ES",
					currencyCode: "EUR",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				},
				validate: (rowData) =>
					!rowData.price_offer || rowData.store_price_offer > 0,
				cellStyle: (cellValue, rowData) => {
					return {
						width: "8%",
						maxWidth: "18%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				headerStyle: {
					width: "8%",
					maxWidth: "8%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.combinations.store_price_offer_1"]}</div>
						<small style={{ fontWeight: 100, textTransform: "none" }}>
							{messages["product.combinations.price_no_iva"]}
						</small>
					</div>
				),
				field: "store_price_offer",
				editable: (cellValue, rowData) =>
					rowData.price_offer ? "always" : "never",
				render: (rowData) => renderStorePriceOffer(rowData, product_data),
				editComponent: (props) => {
					return props.rowData.price_offer ? (
						<TextField
							value={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.store_price_offer = e.target.value;
								if (iva) {
									data.iva_store_price_offer = parseFloat(
										parseFloat(e.target.value) +
										(parseFloat(e.target.value) * parseInt(iva)) / 100
									).toFixed(3);
								}
								props.onRowDataChange(data);
							}}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				type: "currency",
				currencySetting: {
					locale: "es-ES",
					currencyCode: "EUR",
					minimumFractionDigits: 0,
					maximumFractionDigits: 2,
				},
				validate: (rowData) =>
					!rowData.price_offer || rowData.store_price_offer > 0,
				cellStyle: (cellValue, rowData) => {
					return {
						width: "8%",
						maxWidth: "18%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				headerStyle: {
					width: "8%",
					maxWidth: "8%",
				},
			},
			{
				title: messages["product.changed_weight"],
				render: (rowData) => renderWeightChanged(rowData, product_data),
				field: "changed_weight",
				sorting: false,
				editComponent: (props) => {
					return (
						<Switch
							checked={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.changed_weight = e.target.checked;
								if (!e.target.checked) {
									data.store_weight = 0;
								}
								props.onRowDataChange(data);
							}}
						/>
					);
				},
				cellStyle: {
					width: "6%",
					maxWidth: "6%",
				},
				headerStyle: {
					width: "6%",
					maxWidth: "6%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["general_data.weight_1"]}</div>
						<small style={{ fontWeight: 100, textTransform: "none" }}>
							{messages["general_data.weight_2"]}
						</small>
					</div>
				),
				field: "store_weight",
				editable: (cellValue, rowData) =>
					rowData.changed_weight ? "always" : "never",
				render: (rowData) => renderWeight(rowData, product_data),
				editComponent: (props) => {
					return props.rowData.changed_weight ? (
						<TextField
							value={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.store_weight = parseFloat(e.target.value);
								if (e.target.value <= 0) {
									data.changed_weight = false;
								}
								props.onRowDataChange(data);
							}}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				validate: (rowData) =>
					!rowData.changed_weight || rowData.store_weight > 0,
				cellStyle: (cellValue, rowData) => {
					return {
						width: "8%",
						maxWidth: "8%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				headerStyle: {
					width: "8%",
					maxWidth: "8%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.agot"]}</div>
					</div>
				),
				render: renderSpent,
				field: "spent",
				sorting: false,
				editComponent: (props) => {
					return (
						<Switch
							checked={props.value}
							onChange={(e) => {
								var data = { ...props.rowData };
								data.quantity = e.target.checked ? 0 : data.quantity;
								data.spent = e.target.checked ? true : false;

								props.onRowDataChange(data);
							}}
						/>
					);
				},
				cellStyle: {
					width: "6%",
					maxWidth: "6%",
				},
				headerStyle: {
					width: "6%",
					maxWidth: "6%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.stock"]}</div>
					</div>
				),
				render: (rowData) => renderStock(rowData, product_data),
				field: "quantity",
				editComponent: (props) => {
					return !props.rowData.spent ? (
						<TextField
							value={props.value}
							onChange={(e) => props.onChange(parseInt(e.target.value))}
							type="number"
							inputProps={inputProps}
						/>
					) : null;
				},
				cellStyle: (cellValue, rowData) => {
					return {
						width: "10%",
						maxWidth: "10%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "center",
					};
				},
				validate: (rowData) => rowData.quantity >= 0,
				headerStyle: {
					width: "10%",
					maxWidth: "10%",
				},
			},
			{
				title: (
					<div>
						<div>{messages["product.published_short"]}</div>
					</div>
				),
				render: renderPublished,
				field: "published",
				sorting: false,
				editComponent: (props) => {
					return (
						<Switch
							checked={props.value}
							onChange={(e) => props.onChange(e.target.checked)}
						/>
					);
				},
				cellStyle: {
					width: "6%",
					maxWidth: "6%",
				},
				headerStyle: {
					width: "6%",
					maxWidth: "6%",
				},
			},
		];
	};

	const makeColumnsMov = (product_data) => {
		return [
			{
				title: messages["product.combination"],
				render: renderCombinationName,
				field: "attributes_title",
				editable: "never",
				cellStyle: (cellValue, rowData) => {
					return {
						width: "100%",
						maxWidth: "100%",
						opacity: rowData.published ? 1 : 0.26,
						textAlign: "left",
						textTransform: "uppercase",
					};
				},
				headerStyle: {
					width: "100%",
					maxWidth: "100%",
					textAlign: "left",
				},
			},
		];
	};

	/*FIN TABLA COMBINACIONES*/

	//Para indicar si existe un precio de oferta, en un primer momento si el producto tiene precio de oferta se marcará a true, si no false.
	if (productData && taxesData) {
		changePrice(productData, taxesData);
		if (productData.store_price_offer) {
			changePriceOffer(productData, taxesData);
		} else {
			//Borrar campo del total de precio de oferta
			total_price_offer = "";
		}
	}

	/*FORMULARIO*/
	//Validación del formulario
	const schema = yup.object().shape({
		product_data: yup.object().shape({
			category_id: yup
				.string()
				.required(<IntlMessages id="product.validation.category" />)
				.nullable(),
			_translations: yup.object().shape({
				eus: yup.object().shape({
					title: yup
						.string()
						.required(<IntlMessages id="product.validation.title.eus" />),
					body: yup
						.string()
						.required(<IntlMessages id="product.validation.body.eus" />),
				}),
				spa: yup.object().shape({
					title: yup
						.string()
						.required(<IntlMessages id="product.validation.title.spa" />),
					body: yup
						.string()
						.required(<IntlMessages id="product.validation.body.spa" />),
				}),
			}),
			sku: yup
				.string()
				.required(<IntlMessages id="product.validation.sku" />)
				.nullable(),
			store_price: yup
				.number()
				.required(<IntlMessages id="product.validation.store_price" />)
				.moreThan(0, <IntlMessages id="product.validation.store_price_1" />),
			store_price_offer: yup
				.number()
				.moreThan(0, <IntlMessages id="product.validation.store_price_offer" />)
				.lessThan(
					productData ? productData.store_price : 0,
					<IntlMessages id="product.validation.store_price_offer_1" />
				),
			store_quantity: yup
				.number()
				.moreThan(-0.0001, <IntlMessages id="product.validation.positive" />),
			store_weight: yup
				.number()
				.required(<IntlMessages id="product.validation.weight" />)
				.nullable(),
		}),
	});
	/*FIN FORMULARIO*/

	/*ESTILOS*/
	const classes = useStyles();
	//Estilo para el botón Eliminar
	const ColorButton = withStyles((theme) => ({
		root: {
			color: theme.palette.getContrastText(theme.palette.tertiary.main),
			backgroundColor: theme.palette.tertiary.main,
			"&:hover": {
				backgroundColor: theme.palette.tertiary.main,
			},
		},
	}))(Button);
	/*FIN ESTILOS*/

	/*COMPONENTES*/
	// El botón de eliminar solo se mostrará en los productos ya creados
	const delete_button = (id) => {
		return productData && productData.id ? (
			<ColorButton
				variant="contained"
				className={classes.button}
				onClick={() => {
					setDialogDelete({
						action: deleteProduct,
						index: productData.id,
						title: "product.delete.title",
						message: "product.delete.message",
					});
					dispatch(onSetShowDialogDelete(true));
				}}
			>
				<IntlMessages id="app.delete" />
				<DeleteIcon style={{ marginLeft: 10 }}></DeleteIcon>
			</ColorButton>
		) : null;
	};
	/*FIN COMPONENTES*/

	/*Modificar valores del productos que llegan mal de la base de datos */
	if (productData) {
		if (!productData.product_attributes) {
			productData.product_attributes = [];
		}
		if (!productData.features) {
			productData.features = [];
		}
		if (!productData.store_novelty) {
			productData.store_novelty = false;
		}
		if (!productData.sku) {
			productData.sku = "";
		}
		if (!productData.notes) {
			productData.notes = "";
		}
		if (!productData._translations.eus.notes) {
			productData._translations.eus.notes = "";
		}
		if (!productData._translations.spa.notes) {
			productData._translations.spa.notes = "";
		}
	}

	return productData &&
		configData &&
		categoriesData &&
		storeData &&
		dataBrands ? (
		<>
			<Formik
				initialValues={{
					product_data: productData,
					categories: categoriesData.categories,
					has_price_offer: productData.store_price_offer ? true : false,
					total_price: total_price,
					total_price_offer: total_price_offer,
				}}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={schema}
				onSubmit={(values) => {
					//Añadir clave _ids para guardar las características
					if (values.product_data.features.length > 0) {
						values.product_data.features = {
							_ids: values.product_data.features,
						};
					}

					//Si el título o la descripción no está euskera copiar del castellano
					if (values.product_data._translations["eus"].title === "") {
						values.product_data._translations["eus"].title =
							values.product_data._translations["spa"].title;
					}
					if (values.product_data._translations["eus"].body === "") {
						values.product_data._translations["eus"].body =
							values.product_data._translations["spa"].body;
					}

					//GUARDAR EL PRODUCTO
					dispatch(
						onCreateUpdateProduct(
							values.product_data,
							onSetIsEditForm,
							history,
							userData.token
						)
					);
				}}
			>
				{({ values, initialValues, setFieldValue, errors, touched }) => {
					if (!isEqual(values, initialValues)) {
						dispatch(onSetIsEditForm(true));
					}
					return (
						<Form>
							<div className={classes.HeaderProduct}>
								<Grid item xs={12} sm={4}>
									<ComponentHeader
										title={`${messages["product.title.header"]}${values.product_data._translations[contentLanguage].title
											? ": " +
											values.product_data._translations[contentLanguage]
												.title
											: props.match.params.id
												? ""
												: " " + messages["product.new"]
											}`}
									/>
								</Grid>

								<Grid item xs={12} sm={8}>
									<div className={classes.container_link}>
										<Button
											variant="contained"
											color="primary"
											className={classes.button_back}
											startIcon={<BackIcon />}
											onClick={onGoToProductList}
										>
											{messages["product.return"]}
										</Button>
									</div>
								</Grid>
							</div>

							{/* CATEGORÍAS */}
							<Card className={classes.card}>
								<CardContent className={classes.cardContent}>
									<div>
										<h5 className={classes.subtitle}>
											{messages["product.category"]}
										</h5>
									</div>
									<GridContainer className={classes.selects}>
										<FieldArray
											name="product_data.category_levels"
											children={(arrayHelpers) => {
												return show_category(
													values.categories,
													values.product_data.category_levels
														? values.product_data.category_levels[0]
														: 1,
													"",
													0,
													values.product_data.category_levels
														? values.product_data.category_levels
														: [],
													classes,
													contentLanguage,
													arrayHelpers,
													setFieldValue,
													values.product_data.product_attributes,
													values.product_data.combined,
													values.product_data
												);
											}}
										/>
									</GridContainer>
									{errors &&
										errors.product_data &&
										errors.product_data.category_id ? (
										<FormHelperText error>
											{errors.product_data.category_id}
										</FormHelperText>
									) : null}
								</CardContent>
							</Card>
							{/* FIN CATEGORÍAS */}

							{/* DATOS GENERALES */}
							<Card className={classes.card}>
								<CardContent className={classes.cardContent}>
									<div>
										<h5 className={classes.subtitle}>
											{messages["product.general_data"]}
										</h5>
									</div>
									<GridContainer>
										<Grid
											item
											xs={12}
											sm={12}
											md={12}
											lg={12}
											className={classes.switchs}
										>
											<MySwitchField
												name="product_data.published"
												checked={values.product_data.published}
												color="secondary"
												label={messages["product.published"]}
											/>

											<Tooltip title={messages["product.alert.combined"]}>
												<span style={{ textAlign: "left" }}>
													<MySwitchFieldOnChange
														name="product_data.combined"
														checked={values.product_data.combined}
														color="secondary"
														label={messages["product.combined"]}
														onChange={(value) => {
															//Un producto no variable no tiene combinaciones, así que si previamente tenía se deben de eliminar
															if (
																!value &&
																values.product_data.product_attributes.length >
																0
															) {
																setDialogDelete({
																	action: deleteAttributes,
																	index: setFieldValue, //pasar como index la función para modificar los datos del formulario
																	title: "product.combinations.delete",
																	message:
																		"product.combinations.delete.message",
																});
																dispatch(onSetShowDialogDelete(true));
															} else {
																setFieldValue("product_data.combined", value);
																if (value) {
																	setFieldValue(
																		"product_data.store_quantity",
																		0
																	);
																}
															}
														}}
													/>
												</span>
											</Tooltip>

											<MySwitchField
												name="product_data.featured"
												checked={values.product_data.featured}
												color="secondary"
												label={messages["product.featured"]}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div className={classes.title}>
												<InputMultiLanguageMyTextField
													id="title"
													active_language={contentLanguage}
													locales={languages}
													name={"product_data"}
													label={messages["product.title"]}
												/>
											</div>
											<div>
												<MyTextField
													name={"product_data.sku"}
													label={<IntlMessages id="product.sku" />}
													type="text"
													fullWidth
												/>
											</div>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
											<InputMultiLanguageMyTextAreaField
												id="body"
												active_language={contentLanguage}
												locales={languages}
												name={"product_data"}
												label={messages["product.body"]}
												no_label={false}
											/>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div className={classes.formats}>
												<div className={classes.weightField_container}>
													<MyTextField
														name={`product_data.store_weight`}
														label={messages["general_data.weight"]}
														type="number"
														inputProps={inputProps}
														className="weightField"
														sublabel={messages["product.weight.indelicative"]}
													/>
												</div>
												{category &&
													category.formats &&
													category.formats.length > 0 ? (
													<div className={classes.products_formats}>
														<MyTextField
															name={`product_data.format_value`}
															label={messages["general_data.value"]}
															type="text"
															className={classes.weightFieldValue}
														/>
														<div>
															<MySelectFieldOnChange
																label={
																	<IntlMessages id="general_data.format" />
																}
																name={"product_data.format_id"}
																className={classes.select_formats}
																onChange={(value, index) => {
																	setFieldValue(
																		"product_data.format_id",
																		value
																	);
																	if (value === "") {
																		setFieldValue(
																			"product_data.format_value",
																			null
																		);
																	}
																}}
															>
																<MenuItem value="">
																	<em>
																		<IntlMessages id="general_data.format_sin" />
																	</em>
																</MenuItem>
																{map(category.formats, (value) => {
																	return (
																		<MenuItem
																			key={value.id}
																			value={value.id}
																			name={value.id}
																		>
																			{value._translations
																				? value._translations[contentLanguage]
																					.title
																				: value.title}
																		</MenuItem>
																	);
																})}
															</MySelectFieldOnChange>
														</div>
													</div>
												) : null}
											</div>
										</Grid>

										<Grid item xs={12} sm={6} md={6} lg={6}>
											<div className="">
												<Autocomplete
													name={"product_data.store_brand_id"}
													value={dataBrand}
													fullWidth
													disablePortal
													options={dataBrands}
													renderInput={(params) => (
														<TextField
															{...params}
															label={
																<IntlMessages id="product.store_brand_id" />
															}
															variant="outlined"
														/>
													)}
													getOptionLabel={(option) => option.title}
													onChange={(_event, brand) => {
														let value = "";
														if (brand) {
															value = brand.id;
														}

														setFieldValue("product_data.store_brand_id", value);
														setDataBrand(brand);
													}}
												/>
											</div>
										</Grid>
									</GridContainer>
								</CardContent>
							</Card>
							{/* FIN DATOS GENERALES */}

							{/* PRECIO Y STOCK */}
							<Card className={classes.card}>
								<CardContent className={classes.cardContent}>
									<GridContainer>
										<Grid
											item
											xs={12}
											sm={values.product_data.combined ? 12 : 8}
											md={values.product_data.combined ? 12 : 8}
											lg={values.product_data.combined ? 12 : 8}
										>
											<div>
												<h5 className={classes.subtitle}>
													{messages["product.price"]}
												</h5>
											</div>
											<GridContainer>
												<Grid item xs={12} sm={12} md={12} lg={12}>
													<MySwitchFieldOnChange
														name="has_price_offer"
														checked={values.has_price_offer}
														color="secondary"
														label={messages["product.offer"]}
														onChange={(value) => {
															// setHasPriceOffer(value);
															setFieldValue("has_price_offer", value);
															if (!value) {
																setFieldValue(
																	"product_data.store_price_offer",
																	""
																);
																setFieldValue(
																	"total_price_offer",
																	""
																);
															}
														}}
													/>
												</Grid>

												<Grid item xs={12}>
													<div className={classes.store_tax_container}>
														<MySelectFieldOnChange
															label={<IntlMessages id="product.tax" />}
															name={"product_data.store_tax_id"}
															className={classes.store_tax}
															onChange={(value, index) => {
																setFieldValue(
																	"product_data.store_tax_id",
																	value
																);
																const iva_object = findWhere(taxesData, {
																	id: value,
																});
																setIva(iva_object.value);
																if (values.product_data.store_price_offer) {
																	setFieldValue(
																		"total_price_offer",
																		parseFloat(
																			parseFloat(
																				values.product_data.store_price_offer
																			) +
																			(parseFloat(
																				values.product_data.store_price_offer
																			) *
																				parseInt(iva_object.value)) /
																			100
																		).toFixed(3)
																	);
																}
																setFieldValue(
																	"total_price",
																	parseFloat(
																		parseFloat(
																			values.product_data.store_price
																		) +
																		(parseFloat(
																			values.product_data.store_price
																		) *
																			parseInt(iva_object.value)) /
																		100
																	).toFixed(3)
																);
															}}
														>
															{map(taxesData, (value) => {
																return !value.parent_id ? (
																	<MenuItem
																		key={value.id}
																		value={value.id}
																		name={value.title}
																	>
																		{value._translations
																			? value._translations[contentLanguage]
																				.title
																			: value.title}{" "}
																		({value.value}%)
																	</MenuItem>
																) : null;
															})}
														</MySelectFieldOnChange>
													</div>

													<div className={classes.price_fields}>
														<MyTextFieldOnChange
															name={"total_price"}
															label={
																<IntlMessages id="product.store_price_iva" />
															}
															type="number"
															inputProps={inputProps}
															className={classes.store_price_total}
															onChange={(value) => {
																setFieldValue("total_price", value);
																if (iva) {
																	setFieldValue(
																		"product_data.store_price",
																		parseFloat(
																			parseFloat(value * 100) /
																			(100 + parseInt(iva))
																		).toFixed(3)
																	);
																}
															}}
														/>
														<MyTextFieldOnChange
															name={"product_data.store_price"}
															label={<IntlMessages id="product.store_price" />}
															type="number"
															inputProps={inputProps}
															className={classes.store_price}
															onChange={(value) => {
																setFieldValue(
																	"product_data.store_price",
																	value
																);
																setFieldValue(
																	"total_price",
																	parseFloat(
																		parseFloat(value) +
																		(parseFloat(value) * parseInt(iva)) / 100
																	).toFixed(3)
																);
															}}
														/>

														{values.has_price_offer ? (
															<span>
																<MyTextFieldOnChange
																	name={"total_price_offer"}
																	label={
																		<IntlMessages id="product.store_price_offer_iva" />
																	}
																	type="number"
																	inputProps={inputProps}
																	className={classes.store_price_offer}
																	onChange={(value) => {
																		setFieldValue("total_price_offer", value);
																		if (iva) {
																			setFieldValue(
																				"product_data.store_price_offer",
																				parseFloat(
																					parseFloat(value * 100) /
																					(100 + parseInt(iva))
																				).toFixed(3)
																			);
																		}
																	}}
																/>
																<MyTextFieldOnChange
																	name={"product_data.store_price_offer"}
																	label={
																		<IntlMessages id="product.store_price_offer" />
																	}
																	type="number"
																	inputProps={inputProps}
																	className={classes.store_price_offer}
																	onChange={(value) => {
																		setFieldValue(
																			"product_data.store_price_offer",
																			value
																		);
																		setFieldValue(
																			"total_price_offer",
																			parseFloat(
																				parseFloat(value) +
																				(parseFloat(value) * parseInt(iva)) /
																				100
																			).toFixed(3)
																		);
																	}}
																/>
															</span>
														) : null}
													</div>
												</Grid>
											</GridContainer>
										</Grid>

										<Grid item xs={12} sm={4} md={4} lg={4}>
											{!values.product_data.combined ? (
												<>
													<div>
														<h5 className={classes.subtitle}>
															{messages["product.stock"]}
														</h5>
													</div>
													<GridContainer>
														<Grid item xs={12} sm={12} md={12} lg={12}>
															<MySwitchFieldOnChange
																name="has_stock"
																checked={values.has_stock}
																color="secondary"
																label={messages["product.no_stock"]}
																onChange={(value) => {
																	setFieldValue("no_stock", value);
																	if (value) {
																		setFieldValue(
																			"product_data.store_quantity",
																			0
																		);
																	}
																}}
															/>
														</Grid>

														<Grid item xs={12} sm={12} md={12} lg={12}>
															<MyTextFieldOnChange
																name={"product_data.store_quantity"}
																label={
																	<IntlMessages id="product.store_quantity" />
																}
																type="number"
																inputProps={inputProps}
																fullWidth
																onChange={(value) => {
																	if (values.no_stock) {
																		setFieldValue(
																			"product_data.store_quantity",
																			0
																		);
																	} else {
																		setFieldValue(
																			"product_data.store_quantity",
																			parseInt(value)
																		);
																	}
																}}
															/>
														</Grid>
													</GridContainer>
												</>
											) : null}
										</Grid>
									</GridContainer>
								</CardContent>
							</Card>
							{/* FIN PRECIO Y STOCK */}

							{/* IMÁGENES DEL PRODUCTO */}
							<FieldArray
								name="product_data.photos"
								children={(arrayHelpers) => (
									<Card className={classes.card}>
										<CardContent className={classes.cardContent}>
											<div>
												<h5 className={classes.subtitle}>
													{messages["product.images"]}
												</h5>
											</div>
											<GridContainer>
												{values.product_data.photos &&
													values.product_data.photos.length > 0
													? values.product_data.photos.map((photo, index) => {
														return (
															<Grid
																key={index}
																item
																xs={12}
																sm={6}
																md={3}
																lg={3}
															>
																<CardMedia
																	className={classes.media}
																	image={`${process.env.REACT_APP_WEB_URL}${photo.paths.hor}`}
																	title={
																		photo._translations[contentLanguage].title
																	}
																/>
																<InputMultiLanguageMyTextField
																	id="title"
																	active_language={contentLanguage}
																	locales={configData.languages}
																	name={`product_data.photos.${index}`}
																	placeholder={messages["common.title"]}
																/>
																<div
																	style={{
																		fontStyle: "italic",
																		marginTop: 3,
																	}}
																>
																	{messages["images.info"]}
																</div>
																<div
																	className={classes.featured_images_buttons}
																>
																	<FormControlLabel
																		control={
																			<MyCheckboxField
																				type="checkbox"
																				name={`product_data.photos.${index}.main`}
																				color="primary"
																				index={index}
																				onChange={(value, index) => {
																					each(
																						values.product_data.photos,
																						(photo, index_photo) => {
																							setFieldValue(
																								`product_data.photos.${index_photo}.main`,
																								value && index_photo === index
																									? true
																									: false
																							);
																						}
																					);
																				}}
																			/>
																		}
																		label={
																			messages["general_data.featured_image"]
																		}
																	/>

																	<FormControlLabel
																		control={<DeleteIcon className="icon" />}
																		label={messages["common.delete"]}
																		onClick={() => {
																			setDialogDelete({
																				action: arrayHelpers.remove,
																				index: index,
																				title: "image.delete.title",
																				message: "image.delete.message",
																			});
																			dispatch(onSetShowDialogDelete(true));
																		}}
																	/>
																</div>
															</Grid>
														);
													})
													: null}
												<Grid item xs={12} sm={6} md={3} lg={3}>
													<ImageUploading
														onChange={(imageList, addUpdateIndex) => {
															onChangePhotosImages(
																imageList,
																addUpdateIndex,
																setFieldValue,
																values,
																arrayHelpers,
																configData.languages
															);
														}}
														maxFileSize={maxFileSize}
														dataURLKey="data_url"
														multiple
													>
														{({
															imageList,
															onImageUpload,
															onImageUpdate,
															onImageRemove,
															isDragging,
															dragProps,
															errors,
														}) => (
															<>
																<div className={classes.upload_image_container}>
																	<Card
																		className={classes.boardStyle}
																		style={
																			isDragging
																				? {
																					color: "red",
																					backgroundColor:
																						"rgba(0, 0, 0, .1)",
																				}
																				: undefined
																		}
																		onClick={onImageUpload}
																		{...dragProps}
																	>
																		<Avatar className={classes.avatar}>
																			<AddIcon className={classes.addIcon} />
																		</Avatar>
																		<Box
																			mb={4}
																			fontFamily={Fonts.LIGHT}
																			fontSize={{ xs: 16, xl: 18 }}
																		>
																			<h5>
																				{messages["general_data.add_images"]}
																			</h5>
																			<h6>JPG, TIFF, PNG</h6>
																			<h6>(Máx. 1.5 MB)</h6>
																		</Box>
																	</Card>
																</div>
																{errors && (
																	<div className={classes.image_errors}>
																		{errors.acceptType && (
																			<span>
																				{messages["files.acceptType"]}
																			</span>
																		)}
																		{errors.maxFileSize && (
																			<span>
																				{messages["files.maxFileSize"]}
																			</span>
																		)}
																	</div>
																)}
															</>
														)}
													</ImageUploading>
												</Grid>
											</GridContainer>
										</CardContent>
									</Card>
								)}
							/>
							{/* FIN IMÁGENES DEL PRODUCTO */}

							{/* COMBINACIONES */}
							{values.product_data.combined &&
								category &&
								category.attribute_groups.length > 0 ? (
								<Card className={classes.card}>
									<CardContent className={classes.cardContent}>
										<div>
											<h5 className={classes.subtitle}>
												{messages["product.combinations"]}
											</h5>
											<span className={classes.info}>
												{" "}
												{messages["product.attributes.info"]}
											</span>
										</div>

										{values.product_data.product_attributes &&
											values.product_data.product_attributes.length > 0 ? (
											<Media
												queries={{
													small: "(max-width: 599px)",
													other: "(min-width: 600px)",
												}}
											>
												{(matches) => (
													<Fragment>
														{matches.small && (
															<MaterialTable
																title=""
																style={{ boxShadow: "none" }}
																columns={makeColumnsMov(values.product_data)}
																onRowClick={(event, rowData) => {
																	setDialogProductAttribute({
																		product_attribute: rowData,
																		product_data: values.product_data,
																		setFieldValue: setFieldValue,
																		iva: iva,
																	});
																	dispatch(
																		onSetShowDialogProductAttribute(true)
																	);
																}}
																data={values.product_data.product_attributes}
																localization={tableCombinations(
																	contentLanguage
																)}
																options={{
																	actionsColumnIndex: -1,
																	pageSizeOptions: [],
																	paging: false,
																	headerStyle: {
																		fontWeight: 600,
																		textAlign: "center",
																		fontSize: "0.7rem",
																		lineHeight: "1rem",
																		verticalAlign: "top",
																		paddingTop: 25,
																		paddingBottom: 10,
																		zIndex: 0,
																	},
																	rowStyle: {
																		fontSize: "0.9rem",
																		textAlign: "center !important",
																	},
																}}
															/>
														)}
														{matches.other && (
															<MaterialTable
																title=""
																style={{ boxShadow: "none" }}
																columns={makeColumns(values.product_data)}
																data={values.product_data.product_attributes}
																localization={tableCombinations(
																	contentLanguage
																)}
																options={{
																	actionsColumnIndex: -1,
																	pageSizeOptions: [],
																	paging: false,
																	headerStyle: {
																		fontWeight: 600,
																		textAlign: "center",
																		fontSize: "0.7rem",
																		lineHeight: "1rem",
																		verticalAlign: "top",
																		paddingTop: 25,
																		paddingBottom: 10,
																		zIndex: 0,
																	},
																	rowStyle: {
																		fontSize: "0.9rem",
																		textAlign: "center !important",
																	},
																}}
																editable={{
																	onRowUpdate: (newData, oldData) =>
																		new Promise((resolve, reject) => {
																			setTimeout(() => {
																				const dataUpdate = [
																					...values.product_data
																						.product_attributes,
																				];
																				const index = oldData.tableData.id;
																				dataUpdate[index] = newData;
																				setFieldValue(
																					"product_data.product_attributes",
																					[...dataUpdate]
																				);
																				resolve();
																			}, 1000);
																		}),
																	onRowDelete: (oldData) =>
																		new Promise((resolve, reject) => {
																			setTimeout(() => {
																				const dataDelete = [
																					...values.product_data
																						.product_attributes,
																				];
																				const index = oldData.tableData.id;
																				dataDelete.splice(index, 1);
																				setFieldValue(
																					"product_data.product_attributes",
																					[...dataDelete]
																				);
																				resolve();
																			}, 1000);
																		}),
																}}
															/>
														)}
													</Fragment>
												)}
											</Media>
										) : null}

										<div className={classes.button_add_combination}>
											<Button
												variant="contained"
												className={classes.buttonCombination}
												color="secondary"
												onClick={() => {
													setDialogAttributes({
														group_attributes: category.attribute_groups,
														product_attributes:
															values.product_data.product_attributes,
														setFieldValue: setFieldValue,
													});
													dispatch(onSetShowDialogAttributes(true));
												}}
											>
												+ <IntlMessages id="product.add.combination" />
											</Button>
										</div>
									</CardContent>
								</Card>
							) : null}
							{/* FIN COMBINACIONES */}

							{/* CARACTERÍSTICAS */}
							{category ? (
								category.cat_features.length > 0 ? (
									<Card className={classes.card}>
										<CardContent className={classes.cardContent}>
											<div>
												<h5 className={classes.subtitle}>
													{messages["product.features"]}
												</h5>
											</div>
											<GridContainer>
												<Grid item xs={12}>
													{category
														? map(category.cat_features, (value) => {
															return (
																<Accordion key={value.id} name={value.title}>
																	<AccordionSummary
																		expandIcon={<ExpandMoreIcon />}
																		id={value.id}
																	>
																		<Typography className={classes.heading}>
																			{value._translations
																				? value._translations[contentLanguage]
																					.title
																				: value.title}
																		</Typography>
																	</AccordionSummary>
																	<AccordionDetails
																		className={classes.checks_group}
																	>
																		{map(value.features, (feature, index) => {
																			return (
																				<FormControlLabel
																					key={index}
																					style={{ width: "150px" }}
																					label={
																						feature._translations
																							? feature._translations[
																								contentLanguage
																							].title
																							: feature.title
																					}
																					control={
																						<Checkbox
																							name={feature.id.toString()}
																							value={feature.id}
																							checked={
																								contains(
																									values.product_data
																										.features,
																									parseInt(feature.id)
																								)
																									? true
																									: false
																							}
																							onChange={(value, index) => {
																								let features =
																									values.product_data
																										.features;
																								if (index) {
																									if (
																										!contains(
																											features,
																											parseInt(
																												value.target.value
																											)
																										)
																									) {
																										features.push(
																											parseInt(
																												value.target.value
																											)
																										);
																									}
																								} else {
																									if (
																										contains(
																											features,
																											parseInt(
																												value.target.value
																											)
																										)
																									) {
																										features = without(
																											features,
																											parseInt(
																												value.target.value
																											)
																										);
																									}
																								}
																								setFieldValue(
																									`product_data.features`,
																									features
																								);
																							}}
																							inputProps={{
																								"aria-label":
																									"primary checkbox",
																							}}
																						/>
																					}
																				/>
																			);
																		})}
																	</AccordionDetails>
																</Accordion>
															);
														})
														: null}
												</Grid>
											</GridContainer>
										</CardContent>
									</Card>
								) : null
							) : null}
							{/* FIN CARACTERÍSTICAS */}

							{/* NOTA */}
							<Card className={classes.card} style={{ marginBottom: 100 }}>
								<CardContent className={classes.cardContent}>
									<div>
										<h5 className={classes.subtitle}>
											{messages["product.note"]}
										</h5>
									</div>
									<GridContainer>
										<Grid item xs={12} sm={3} md={3} lg={3}>
											<div>{messages["product.note.messagge1"]}</div>
											<div>{messages["product.note.messagge2"]}</div>
										</Grid>
										<Grid
											item
											xs={12}
											sm={9}
											md={9}
											lg={9}
											className={classes.notes}
										>
											<InputMultiLanguageMyTextAreaField
												id="notes"
												active_language={contentLanguage}
												locales={languages}
												name={"product_data"}
												no_label={true}
											/>
										</Grid>
									</GridContainer>
								</CardContent>
							</Card>
							{/* FIN NOTA */}

							<Grid className={classes.card_button}>
								<div className={classes.buttons}>
									{delete_button(values.product_data.id)}
									<Button
										variant="contained"
										className={classes.button}
										type="submit"
										color="primary"
									>
										<IntlMessages id="app.save" />
									</Button>
								</div>
							</Grid>
						</Form>
					);
				}}
			</Formik>
			<AlertFormDelete
				action={dialog_delete.action}
				index={dialog_delete.index ? dialog_delete.index : ""}
				title={dialog_delete.title}
				message={dialog_delete.message}
			></AlertFormDelete>
			<ModalFormAttributes
				group_attributes={dialog_attributes.group_attributes}
				product_attributes={dialog_attributes.product_attributes}
				setFieldValue={dialog_attributes.setFieldValue}
			></ModalFormAttributes>
			<ModalFormProductAttribute
				product_attribute={dialog_product_attribute.product_attribute}
				product_data={dialog_product_attribute.product_data}
				setFieldValue={dialog_product_attribute.setFieldValue}
				iva={dialog_product_attribute.iva}
			></ModalFormProductAttribute>
			<InfoView />
		</>
	) : null;
};

export default Product;
