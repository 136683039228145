import { FETCH_START, FETCH_SUCCESS } from "../../shared/constants/ActionTypes";

import { jwtAxios, InvalidToken } from "../../@crema/services/auth/";

export const onGetBrands = (setDataBrands, category, token) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .get(`brands/${category}`)
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        setDataBrands(data.brands);
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};
