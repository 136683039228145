import {
  SET_IS_EDIT_FORM,
  SET_SHOW_DIALOG_CHANGE_PAGE,
  SET_SHOW_DIALOG_DELETE,
  SET_SHOW_DIALOG_ATTRIBUTES,
  SET_SHOW_DIALOG_PRODUCT_ATTRIBUTE,
  SET_SHOW_DIALOG_OK,
  SET_SHOW_DIALOG_CONFIRM
} from '../../shared/constants/ActionTypes';

export const onSetIsEditForm = (is_edit_form) => {
  return (dispatch) => {
    dispatch({type: SET_IS_EDIT_FORM, payload: is_edit_form});
  };
};

export const onSetShowDialogChangePage = (show_dialog_change_page) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_CHANGE_PAGE, payload: show_dialog_change_page});
  };
};

export const onSetShowDialogDelete = (show_dialog_delete) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_DELETE, payload: show_dialog_delete});
  };
};

export const onSetShowDialogAttributes = (show_dialog_attributes) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_ATTRIBUTES, payload: show_dialog_attributes});
  };
};

export const onSetShowDialogProductAttribute = (show_dialog_product_attribute) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_PRODUCT_ATTRIBUTE, payload: show_dialog_product_attribute});
  };
};

export const onSetShowDialogOk= (show_dialog_ok) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_OK, payload: show_dialog_ok});
  };
};

export const onSetShowDialogConfirm= (show_dialog_confirm) => {
  return (dispatch) => {
    dispatch({type: SET_SHOW_DIALOG_CONFIRM, payload: show_dialog_confirm});
  };
};