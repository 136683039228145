import {
  GET_CONFIG,
  GET_CATEGORIES
} from '../../shared/constants/ActionTypes';

const initialState = {
  configData: null,
  categoriesData: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        configData: action.payload,
      };
    case GET_CATEGORIES:
        return {
          ...state,
          categoriesData: action.payload,
        };
    default:
      return state;
  }
};