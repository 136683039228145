import React from 'react';
import {Redirect} from 'react-router-dom';

import {createRoutes} from '../@crema/utility/Utils';
import {muiComponentConfigs} from './muiComponents';
import {errorPagesConfigs} from './errorPages';
import {extraPagesConfigs} from './extraPages';
import {authRouteConfig} from './auth';
import {initialUrl} from '../shared/constants/AppConst';
import {adminConfigs} from './admin';

const routeConfigs = [
  ...authRouteConfig,
  ...muiComponentConfigs,
  ...errorPagesConfigs,
  ...extraPagesConfigs,
  ...adminConfigs
];

const routes = [
  ...createRoutes(routeConfigs),
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={initialUrl} />,
  },
  {
    component: () => <Redirect to='/404' />,
  },
];

export default routes;