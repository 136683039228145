import {SET_FUTURE_URL} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  actual_url: ''
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case SET_FUTURE_URL: {
      return {
        ...state,
        actual_url: action.payload,
      };
    }
    
    default:
      return state;
  }
};