import { makeStyles, fade } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

export default makeStyles((theme) => {
  return {
    card: {
      marginBottom: 32,
      paddingBottom: 16,
    },
    subtitle: {
      textTransform: "uppercase",
      marginBottom: 10,
    },
    cardContent: {
      paddingLeft: 32,
      paddingRight: 32,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 16,
        paddingRight: 16,
      },
    },
    link: {
      cursor: "pointer",
      marginLeft: 30,
      alignItems: "center",
      display: "flex",
      "& svg": {
        fontSize: 12,
        marginRight: 5,
      },
    },
    buttons: {
      [theme.breakpoints.up("lg")]: {
        paddingLeft: "19rem",
      },
      [theme.breakpoints.up("xl")]: {
        paddingLeft: "21.6rem",
      },
    },
    button: {
      width: 170,
      marginRight: 10,
      marginLeft: 10,
      [theme.breakpoints.down("xs")]: {
        width: 130,
        marginRight: 5,
        marginLeft: 5,
      },
    },
    buttonCombination: {
      marginTop: 20,
    },
    selects: {
      display: "flex",
      justifyContent: "flex-start",
    },
    switchs: {
      textAlign: "right",
      "& input": {
        cursor: "pointer",
      },
      [theme.breakpoints.down("xs")]: {
        display: "flex",
        flexWrap: "wrap",
      },
      "& .switch": {
        display: "flex",
        flexDirection: "row",
      },
    },
    title: {
      marginBottom: 36,
    },
    price: {
      marginLeft: 10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    select: {
      paddingTop: 7,
    },
    selectFeature: {
      width: "100%",
    },
    addButton: {
      marginLeft: 16,
      boxShadow: "none",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        height: 35,
        width: 42,
      },
      [theme.breakpoints.down("xs")]: {
        height: 35,
        width: 46,
      },
    },
    delete_button: {
      marginBottom: "-15px",
    },
    media: {
      height: 0,
      paddingTop: "56.25%",
    },
    card_button: {
      backgroundColor: fade(theme.palette.common.white, 0.9),
      borderTop: "1px solid #E7E7E7",
      textAlign: "center",
      padding: 20,
      position: "fixed",
      left: "0",
      bottom: "0",
      width: "100%",
      zIndex: 1,
    },
    featured_images_buttons: {
      display: "flex",
      flexDirection: "column",
      "& .icon": {
        marginLeft: 8,
        marginRight: 9,
        fontSize: 25,
      },
    },
    boardStyle: {
      borderRadius: theme.overrides.MuiCard.root.borderRadius,
      cursor: "pointer",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: 20,
      textAlign: "center",
      backgroundColor: theme.palette.background.paper,
      height: 224,
      border: "2px dashed",
      borderColor: grey[600],
    },
    avatar: {
      width: 40,
      height: 40,
      [theme.breakpoints.up("xl")]: {
        width: 60,
        height: 60,
      },
      marginBottom: 20,
    },
    container_title_language: {
      display: "flex",
      justifyContent: "space-between",
    },
    container_link: {
      display: "flex",
      justifyContent: "flex-end",
      alignItems: "center",
      marginBottom: 10,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        alignItems: "flex-end",
        "& button": {
          fontSize: 12,
          "& svg": {
            fontSize: "12px !important",
          },
        },
      },
    },
    upload_image_container: {
      paddingTop: "16px !important",
      cursor: "pointer",
    },
    notes: {
      paddingTop: "0 !important",
    },
    heading: {
      fontWeight: 600,
    },
    checks_group: {
      flexWrap: "wrap",
    },
    button_add_combination: {
      textAlign: "center",
    },
    pagination: {
      display: "flex",
      justifyContent: "center",
      paddingTop: 20,
      paddingBottom: 20,
      "& ul": {
        display: "flex",
        listStyle: "none",
        border: "1px solid #404040",
        cursor: "pointer",
        "& li": {
          padding: 10,
          borderRight: "1px solid #404040",
          "&.pagination_active": {
            backgroundColor: "#404040",
            color: "#fff",
          },
          "&:hover": {
            backgroundColor: "#404040",
            color: "#fff",
            cursor: "pointer",
          },
          "&:last-child": {
            borderRight: "none",
          },
        },
      },
    },
    color: {
      backgroundColor: "#ccc",
      height: 15,
      width: 15,
      marginRight: 10,
    },
    attribute: {
      display: "flex",
      alignItems: "center",
      "& label": {
        minWidth: 100,
        display: "flex",
        justifyContent: "space-between",
        marginLeft: 0,
      },
    },
    formControl: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
    },
    formControlFormats: {
      display: "flex",
      flexDirection: "row",
      alignItems: "flex-end",
      paddingLeft: 45,
      [theme.breakpoints.down("xs")]: {
        paddingLeft: 0,
      },
    },
    selectFormat: {
      width: "100%",
    },
    store_price_total: {
      marginRight: 20,
      width: 230,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: "100%",
      },
    },
    store_price: {
      marginRight: 20,
      width: 230,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: "100%",
      },
    },
    store_price_offer: {
      marginRight: 20,
      width: 230,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: "100%",
      },
      "& input": {
        color: "#F4445A",
      },
      "& label": {
        minWidth: 140,
      },
    },
    store_tax_container: {
      "& input": {
        paddingTop: 7,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down("xs")]: {
          marginRight: 0,
          width: "100%",
        },
      },
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    store_tax: {
      paddingTop: 7,
      marginRight: 20,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: "100%",
      },
    },
    store_brand: {
      paddingTop: 7,
      marginRight: 20,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginRight: 0,
        width: "100%",
      },
    },

    formats: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    weightField_container: {
      marginBottom: 20,
      "& .weightField": {
        display: "flex",
        flexDirection: "column",
        marginRight: 20,
        [theme.breakpoints.down("xs")]: {
          marginTop: 10,
          width: "100%",
        },
        "& label": {
          width: "200px",
        },
      },
    },
    weightFieldValue: {
      marginRight: 20,
      width: 60,
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        marginTop: 10,
        width: "100%",
      },
    },
    products_formats: {
      display: "flex",
      [theme.breakpoints.down("xs")]: {
        marginTop: 20,
        flexDirection: "column",
      },
    },
    image_errors: {
      display: "block",
      marginTop: 10,
      color: "#f44336",
    },
    price_fields: {
      display: "flex",
      flexWrap: "wrap",
    },
    button_back: {
      [theme.breakpoints.down("xs")]: {
        marginRight: 10,
        marginBottom: 10,
        fontSize: 12,
      },
    },
    info: {
      fontStyle: "italic",
      fontSize: 12,
      color: "#f44336",
    },
    select_formats: {
      paddingTop: 7,
      width: 200,
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },
    HeaderProduct: {
      display: "flex",
      alignItems: "center",
      marginBottom: 20,
      [theme.breakpoints.down("xs")]: {
        flexDirection: "column",
        alignItems: "normal",
        marginBottom: 0,
      },
    },
  };
});
