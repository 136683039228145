export const initialUrl = "/admin/general_data/data"; // this url will open after login

export const defaultStates = [
  {
    id: 1,
    title: "Estado 1",
    iso_code: "iso",
    country_id: 1,
    _translations: {
      eus: {
        title: "Estado 1 eus",
      },
      spa: {
        title: "Estado 1 spa",
      },
    },
  },
  {
    id: 2,
    title: "Estado 2",
    iso_code: "iso",
    country_id: 1,
    _translations: {
      eus: {
        title: "Estado 2 eus",
      },
      spa: {
        title: "Estado 2 spa",
      },
    },
  },
];

export const emptyStore = {
  title: "",
  email: "",
  phone: "",
  slug: "",
  vat: "",
  logo: {},
  name: "",
  city: "",
  postcode: "",
  horaries: "",
  body: "",
  web: "",
  store_socials: [],
  store_addresses: [],
  category_ids: [],
  photos: [],
  _translations: {
    eus: {
      title: "",
      body: "",
      address: "",
      horaries: "",
    },
    spa: {
      title: "",
      body: "",
      address: "",
      horaries: "",
    },
  },
};

export const defaultProductEmpty = {
  title: "",
  body: "",
  sku: "",
  published: true,
  store_price: "",
  store_price_iva: "",
  store_quantity: 0,
  store_novelty: false,
  store_price_offer: "",
  total_price_offer: "",
  store_price_offer_iva: "",
  store_weight: "",
  category: {},
  categories: [],
  category_id: null,
  whithout_stock: false,
  only_with_attributes: false,
  _translations: {
    eus: {
      title: "",
      body: "",
    },
    spa: {
      title: "",
      body: "",
    },
  },
  store_tax_id: 1,
  notes: "",
  featured: false,
  features: [],
  category_levels: [],
  product_attributes: [],
  photos: [],
  format_id: "",
  format_value: "",
};

export const tableProducts = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registros de productos",
          filterRow: {
            filterTooltip: "Filtro",
          },
          editRow: {
            deleteText: "¿Estás seguro de eliminar el producto?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Ezabatu",
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Produktuen erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
          editRow: {
            deleteText: "Ziur zaude produktua ezabatu nahi duzula?",
            cancelTooltip: "Utzi",
            saveTooltip: "Gorde",
          },
        },
      };
    default:
      break;
  }
};

export const tableOrders = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registros de pedidos",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Ezabatu",
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Eskaeren erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
        },
      };
    default:
      break;
  }
};

export const tableOrder = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registro de pedido",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Eskaera erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
        },
      };
    default:
      break;
  }
};

export const tableCombinations = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registros de combinaciones",
          filterRow: {
            filterTooltip: "Filtro",
          },
          editRow: {
            deleteText: "¿Estás seguro de eliminar la combinación?",
            cancelTooltip: "Cancelar",
            saveTooltip: "Guardar",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Ezabatu",
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Konbinazio-erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
          editRow: {
            deleteText: "Ziur zaude konbinazioa ezabatu nahi duzula?",
            cancelTooltip: "Utzi",
            saveTooltip: "Gorde",
          },
        },
      };
    default:
      break;
  }
};

export const tableRefunds = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registros de devoluciones",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Ezabatu",
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Itzulketen erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
        },
      };
    default:
      break;
  }
};

export const tableRefund = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registro de devolución",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Eskaera erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
        },
      };
    default:
      break;
  }
};

export const tableInvoices = (locale) => {
  switch (locale) {
    case "spa":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} de {count}",
          labelRowsSelect: "filas",
          labelRowsPerPage: "Filas por página",
        },
        toolbar: {
          nRowsSelected: "{0} fila(s) seleccionadas",
          searchTooltip: "Buscar",
          searchPlaceholder: "Buscar",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Eliminar",
          editTooltip: "Editar",
          emptyDataSourceMessage: "Sin registros de facturas",
          filterRow: {
            filterTooltip: "Filtro",
          },
        },
      };
    case "eus":
      return {
        pagination: {
          labelDisplayedRows: "{from}-{to} tik {count}",
          labelRowsSelect: "filak",
          labelRowsPerPage: "Filak orrialde bakoitzeko",
        },
        toolbar: {
          nRowsSelected: "Hautatutako {0} fila(k)",
          searchTooltip: "Bilatu",
          searchPlaceholder: "Bilatu",
        },
        header: {
          actions: "",
        },
        body: {
          deleteTooltip: "Ezabatu",
          editTooltip: "Editatu",
          emptyDataSourceMessage: "Fakturen erregistrorik gabe",
          filterRow: {
            filterTooltip: "Filtroa",
          },
        },
      };
    default:
      break;
  }
};
