import React from 'react';
import {ListItem, ListItemText} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {Badge} from '../../../index';
import Box from '@material-ui/core/Box';
import IntlMessages from '../../../utility/IntlMessages';
import useStyles from './VerticalItem.style';
import {makeStyles} from '@material-ui/core';

/*ESTILOS PERSONALIZADOS DEL COMPONENTE */
const useStylesMenu = makeStyles((theme) => {
  return {
    icon: {
      fontFamily:'toptopa-icons',
      color: "#404040",
      fontSize: "1.2rem"
    },
  };
});
/*FIN ESTILOS PERSONALIZADOS DEL COMPONENTE */


const VerticalItemOnClick = (props) => {
  const classes = useStyles(props);
  const classes_menu = useStylesMenu();
  const {item,handleClick} = props;

  return ( <ListItem
      onClick={handleClick} 
      button
      className={clsx(classes.navItem, 'nav-item')}
      >
      {item.icon && (
        <Box component='span' mr={6}>
          <div 
          className={classes_menu.icon}
          >
            <div className={item.icon}></div>
          </div>  
        </Box>
      )}
      <ListItemText
        primary={<IntlMessages id={item.messageId} />}
        classes={{primary: 'nav-item-text'}}
      />
      {item.count && (
        <Box mr={1} clone>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}
    </ListItem>
  );
};

VerticalItemOnClick.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string
  }),
};

VerticalItemOnClick.defaultProps = {};

export default VerticalItemOnClick;