import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_CONFIG,
  GET_CATEGORIES,
} from "../../shared/constants/ActionTypes";

import { InvalidToken, jwtAxios } from "../../@crema/services/auth/";

export const onGetConfig = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get("config")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_CONFIG, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Error",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetCategories = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get("categories")
      .then((data) => {
        if (data.status === 200) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: GET_CATEGORIES, payload: data.data });
        } else {
          dispatch({
            type: FETCH_ERROR,
            payload: "Error",
          });
        }
      })
      .catch((error) => {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
};

export const onGetCategory = (id, setCategory, setFieldValue) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios
      .get(`categories/${id}`)
      .then(({ data }) => {
        if (data.category) {
          setCategory(data.category);
          //Si no tiene hijos es que es la última categoría, así que hay que asignar valor a category_id
          if (setFieldValue) {
            if (data.category.children.length === 0) {
              setFieldValue(`product_data.category_id`, id);
            } else {
              setFieldValue(`product_data.category_id`, null);
            }
          }
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch((error) => {
        InvalidToken(error, dispatch);
        //dispatch({type: FETCH_ERROR, payload: error.message});
      });
  };
};
