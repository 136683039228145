import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import IntlMessages from '../../../@crema/utility/IntlMessages';

const AppLogo = () => {
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
      <a href={process.env.REACT_APP_EXTERNAL_URL} target="_blank" rel="noopener noreferrer">           
        <img
          className={classes.logo}
          src={require('assets/images/logo-toptopa.png')}
          alt={<IntlMessages id='app.name' />}
        />
      </a>
    </Box>
  );
};

export default AppLogo;
