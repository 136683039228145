import React from 'react';
import {NavLink} from 'react-router-dom';
import {useSelector,useDispatch} from 'react-redux';
import {onSetShowDialogChangePage,onSetFutureURL} from '../../../redux/actions';
import { Beforeunload } from 'react-beforeunload';

const AppNavLink = React.forwardRef((props, ref) => {

  const isEditForm = useSelector(({forms}) => forms.is_edit_form); //Comprobar si se está editando un formulario y no ha sido guardado
  const dispatch = useDispatch();

  function handleLink(event,url) {
    event.preventDefault();
    dispatch(onSetShowDialogChangePage(true));
    //Guardar la futura url por si el cliente quiere continuar sin guardar.
    dispatch(onSetFutureURL(url));
  }
  
  if (isEditForm){
    return <>
    <NavLink innerRef={ref} {...props} onClick={(e) => {
      handleLink(e, props.to)
    }}/>
    <Beforeunload onBeforeunload={() => "You'll lose your data!"} />
    </>
  }
  else{
    return <NavLink innerRef={ref} {...props} />
  }
});

export default AppNavLink;