import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

const Scrollbar = React.forwardRef((props, ref) => {
  const {
    children,
    history,
    scrollToTop,
    staticContext,
    className,
    ...others
  } = props;

  return (
    <div
      {...others}
      className={className}>
      {children}
    </div>
  );
});

export default withRouter(Scrollbar);

Scrollbar.defaultProps = {
  className: '',
};

Scrollbar.propTypes = {
  children: PropTypes.node.isRequired,
};