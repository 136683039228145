import React, {useState} from 'react';
import defaultConfig from './defaultConfig';
import AppContext from '../AppContext';
import routes from '../../../modules';
import PropTypes from 'prop-types';
import {ThemeMode,Locale} from '../../../shared/constants/AppEnums';

const ContextProvider = ({children}) => {
  const [theme, updateTheme] = useState(defaultConfig.theme);
  const [footer, setFooter] = useState(defaultConfig.footer);
  const [footerType, setFooterType] = useState(defaultConfig.footerType);
  const [themeMode, updateMode] = useState(defaultConfig.themeMode);
  const [headerMode, updateHeaderMode] = useState(defaultConfig.headerMode);
  const [themeStyle, updateThemeStyle] = useState(defaultConfig.themeStyle);
  const [layoutType, updateLayoutStyle] = useState(defaultConfig.layoutType);
  const [contentLanguage, setContentLanguage] = useState(Locale[localStorage.getItem("locale")] ? Locale[localStorage.getItem("locale")] : process.env.REACT_APP_DEFAULT_LOCALE_INTERFACE);

  //Obtener el idioma del navegador para mostrar el interfaz en ese idioma.
  //Al loguearse el usuario esto puede cambiar. Así como cuando el usuario cambia el parámetro dentro de datos generales
  const userLang = navigator.language || navigator.userLanguage;
  const [locale, changeLocale] = useState(Locale[localStorage.getItem("locale")] ? Locale[localStorage.getItem("locale")] : process.env.REACT_APP_DEFAULT_LOCALE_INTERFACE);

  const [navStyle, changeNavStyle] = useState(defaultConfig.navStyle);
  const [rtAnim, changeRTAnim] = useState(defaultConfig.rtAnim);

  const [primary, updatePrimaryColor] = useState(
    defaultConfig.theme.palette.primary.main,
  );
  const [sidebarColor, updateSidebarColor] = useState(
    defaultConfig.theme.palette.sidebar.bgColor,
  );
  const [secondary, updateSecondaryColor] = useState(
    defaultConfig.theme.palette.secondary.main,
  );

  // const updateThemeMode = (themeMode) => {
  //   let currentTheme = {...theme};
  //   if (themeMode === ThemeMode.DARK) {
  //     currentTheme.palette.type = ThemeMode.DARK;
  //     currentTheme.palette.background = {
  //       paper: '#313541',
  //       default: '#393D4B',
  //     };
  //     currentTheme.palette.text = {
  //       primary: '#404040',
  //       secondary: 'rgba(255, 255, 255, 0.67)',
  //       disabled: 'rgba(255, 255, 255, 0.38)',
  //       hint: 'rgba(255, 255, 255, 0.38)',
  //     };
  //   } else {
  //     currentTheme.palette.type = ThemeMode.LIGHT;
  //     currentTheme.palette.background = {
  //       paper: '#FFFFFF',
  //       default: '#f3f4f6',
  //     };
  //     currentTheme.palette.text = {
  //       primary: 'rgba(0, 0, 0, 0.87)',
  //       secondary: 'rgba(0, 0, 0, 0.67)',
  //       disabled: 'rgba(0, 0, 0, 0.38)',
  //       hint: 'rgba(0, 0, 0, 0.38)',
  //     };
  //   }
  //   updateTheme(currentTheme);
  //   updateMode(themeMode);
  // };

  return (
    <AppContext.Provider
      value={{
        theme,
        primary,
        secondary,
        themeMode,
        locale,
        changeLocale,
        navStyle,
        headerMode,
        routes,
        layoutType,
        rtAnim,
        sidebarColor,
        footer,
        footerType,
        themeStyle,
        contentLanguage,
        setContentLanguage
      }}>
      {children}
    </AppContext.Provider>
  );
};

export default ContextProvider;

ContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
