import React, { useState, useEffect } from "react";
import { Select, MenuItem } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import { map } from "underscore";
import { MyTextFieldMultiLanguage } from "../../../../@crema/core/Formik/";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    width: "100%",
    alignItems: "flex-end",
    flexWrap: "wrap",
    "& label": {
      paddingLeft: 10,
    },
    "& input": {
      paddingLeft: 10
    },
    "& svg": {
      color: theme.palette.primary.contrastText,
    },
    "& p": {
      paddingLeft: 10,
    },
  },
  language: {
    color: theme.palette.primary.contrastText,
    height: 35,
    paddingTop: 0,
    width: 45
  },
  input_language : {
    width: "100%",
    flexGrow: "1",
    flexShrink: "1",
    flexBasis: "calc(100% - 45px)"
  }
}));

const InputMultiLanguageMyTextField = ({
  id,
  active_language,
  locales,
  name,
  label,
  placeholder,
}) => {
  const classes = useStyles();

  //Controlar el idioma del elemento
  const [activeLanguage, setActiveLanguage] = useState(active_language);
  
  const handleChangeLanguage = (e) => {
    setActiveLanguage(e.target.value);
  };

  useEffect(() => {
    setActiveLanguage(active_language);
  },[active_language]);

  return (
    <div className={classes.root}>
      <Select
        className={classes.language}
        style={{
          backgroundColor:
            process.env.REACT_APP_DEFAULT_LOCALE === activeLanguage
              ? "#979797"
              : "#1DBDBD",
        }}
        value={activeLanguage}
        onChange={handleChangeLanguage}
      >
        {map(locales, (locale) => {
          return (
            <MenuItem value={locale.iso3} key={locale.id}>
              {locale.iso2.toUpperCase()}
            </MenuItem>
          );
        })}
      </Select>
      
      {map(locales, (locale) => {
        return (
          <MyTextFieldMultiLanguage
            className={classes.input_language}
            key={locale.id}
            type="text"
            display={
              activeLanguage === locale.iso3
                ? "block"
                : "none"
            }
            name={`${name}._translations.${locale.iso3}.${id}`}
            label={label}
            placeholder={placeholder}
          />
        );
      })}
    </div>
  );
};

export default InputMultiLanguageMyTextField;
