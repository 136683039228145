import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {
  Fonts,
  FooterType,
  HeaderType,
  LayoutType,
  NavStyle,
  RouteTransition,
  ThemeMode,
  ThemeStyle,
  ThemeStyleRadius,
} from '../../../shared/constants/AppEnums';

const breakpoints = createBreakpoints({});
const cardRadius = ThemeStyleRadius.MODERN;
const defaultConfig = {
  theme: {
    spacing: 4,
    direction: 'ltr', //ltr, rtl
    palette: {
      type: ThemeMode.LIGHT,
      background: {
        paper: '#FFFFFF',
        default: '#F2F2F2',
      },
      primary: {
        main: '#404040',
        contrastText: '#fff',
      },
      secondary: {
        main: '#1DBDBD',
      },
      tertiary: {
        main : '#F4445A',
      },
      sidebar: {
        bgColor: '#313541',
        textColor: '#404040',
      },
      gray: {
        50: '#fafafa',
        100: '#f7fafc',
        200: '#edf2f7',
        300: '#E0E0E0',
        400: '#c5c6cb',
        500: '#A8A8A8',
        600: '#666666',
        700: '#4a5568',
        800: '#201e21',
        900: '#1a202c',
        A100: '#d5d5d5',
        A200: '#aaaaaa',
        A400: '#303030',
        A700: '#616161',
      },
      text: {
        primary: '#404040',
        secondary: 'rgba(0, 0, 0, 0.67)',
        disabled: 'rgba(0, 0, 0, 0.38)',
        hint: 'rgba(0, 0, 0, 0.38)',
        white: '#fff',
      },
      btn: {
        hover: 'rgba(0, 0, 0, 0.08)',
      },
    },
    status: {
      danger: 'orange',
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    typography: {
      fontFamily: [Fonts.REGULAR, 'sans-serif'].join(','),
    },
    overrides: {
      MuiTypography: {
        h1: {
          fontSize: 36,
        },
        h2: {
          fontSize: 30,
        },
        h3: {
          fontSize: 24,
        },
        h4: {
          fontSize: 22,
        },
        h5: {
          fontSize: 18,
        },
        h6: {
          fontSize: 16,
        },
        subtitle1: {
          fontSize: 18,
        },
        subtitle2: {
          fontSize: 20,
        },
        body1: {
          fontSize: 16,
        },
        body2: {
          fontSize: 14,
        },
      },
      MuiToggleButton: {
        root: {
          borderRadius: cardRadius,
        },
      },
      MuiCardLg: {
        root: {
          borderRadius:
            cardRadius === ThemeStyleRadius.STANDARD
              ? ThemeStyleRadius.STANDARD
              : ThemeStyleRadius.MODERN + 20,
        },
      },
      MuiCard: {
        root: {
          borderRadius: cardRadius,
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
          [breakpoints.down('xs')]: {
            borderRadius: 0
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: cardRadius,
          boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
          [breakpoints.down('md')]: {
            paddingTop: '8px !important',
            paddingBottom: '8px !important',
          },
        },
        contained: {
          boxShadow: 'none',
          "&:hover": {
            boxShadow: 'none',  
          }
        }
      },
      MuiFormLabel:{
        root: {
          color: '#979797'
        }
      },
      MuiInputBase:{
        root: {
          paddingTop: 10,
        },
        input: {
          "&[type=number]" : {
            height: "19px",
            "&:hover::-webkit-inner-spin-button" : {  
                width: "30px",
                height: "35px"
            },
            "&:hover::-webkit-outer-spin-button" : {  
              width: "30px",
              height: "35px"
          }
          }
        }     
      },
      MuiInputLabel:{
        shrink: {
          fontSize: "1.2rem",
          backgroundColor : "#fff",
          paddingRight: 5
        }
      },
      MuiDialog:{
        paperWidthSm:{
          minWidth: 350,
          minHeight: 200
        }
      },
      MuiPopover : {
        paper: {
          minWidth: 200,
          padding: 5,
        }
      },
      MuiTableCell:{
        root : {
          fontWeight: "400 !important",
          padding: 12
        }
      },
      MuiTableHead:{
        root : {
          fontWeight: "400 !important",
          textTransform: "uppercase"
        }
      },
      MuiTableSortLabel : {
        icon : {
          opacity : 0.5
        },
        root : {
          textTransform : "uppercase",
          fontWeight : 400,
          flexDirection: "row",
          alignItems: "start"
        },
        active :{
          "& .MuiTableSortLabel-icon":{
            color: "#1dbdbd !important",
          }
        }
      },
      MuiSelect :{
        selectMenu :{
          paddingLeft : 5,
          display: "flex",
          alignItems: "center",
          height: "100%"
        }
      }
    },
  },
  themeStyle: ThemeStyle.MODERN,
  themeMode: ThemeMode.LIGHT,
  navStyle: NavStyle.HEADER_USER,
  layoutType: LayoutType.FULL_WIDTH,
  footerType: FooterType.FLUID,
  headerType: HeaderType.DARK,
  rtAnim: RouteTransition.NONE,
  footer: false
};
export default defaultConfig;
