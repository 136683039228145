import {combineReducers} from 'redux';
import {connectRouter} from 'connected-react-router';
import Settings from './Setting';
import Common from './Common';
import JWTAuth from './JWTAuth';
import Forms from './Forms';
import Urls from './Urls';
import Config from './Config';
import Store from './Store';
import Product from './Product';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    jwtAuth: JWTAuth,
    common: Common,
    forms: Forms,
    urls: Urls,
    config: Config,
    store: Store,
    product: Product
  });