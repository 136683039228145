import {
  SIGNOUT_JWT_USER_SUCCESS,
  UPDATE_JWT_AUTH_USER,
  UPDATE_JWT_AUTH_TOKEN,
  GET_JWT_USER_DATA,
  GET_USER_COMPLETE_DATA,
  SET_JWT_TOKEN_SET,
  SET_LOCALE
} from '../../shared/constants/ActionTypes';

const INIT_STATE = {
  user: null,
  user_complete: null,
  token: null,
  locale: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case UPDATE_JWT_AUTH_USER: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case UPDATE_JWT_AUTH_TOKEN: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case SIGNOUT_JWT_USER_SUCCESS: {
      return {
        ...state,
        user: null,
        token: null,
        locale: null,
      };
    }
    case GET_JWT_USER_DATA: {
      return {
        ...state,
        user: action.payload,
      };
    }
    case GET_USER_COMPLETE_DATA: {
      return {
        ...state,
        user_complete: action.payload,
      };
    }
    case  SET_JWT_TOKEN_SET: {
      return {
        ...state,
        token: action.payload,
      };
    }
    case  SET_LOCALE: {
      return {
        ...state,
        locale: action.payload,
      };
    }
    default:
      return state;
  }
};
