import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_STORE_DATA,
  SHOW_MESSAGE,
  GET_JWT_USER_DATA,
} from "../../shared/constants/ActionTypes";
import {
  jwtAxios,
  jwtAxiosFiles,
  InvalidToken,
} from "../../@crema/services/auth/";
import axios from "axios";
import { forEach, isUndefined } from "underscore";
import { emptyStore } from "../../../src/shared/constants/AppConst";
import { Locale } from "../../shared/constants/AppEnums";

//Obtener datos de la tienda
export const onGetStore = (token, saveLocalStorage = false, force = false) => {
  // Comprueba si la información está en local. De ser así la devuelve, si no
  // hace una petición al servidor
  if (!force) {
    const storeLocalStorage = localStorage.getItem("store");
    if (storeLocalStorage && !saveLocalStorage) {
      return (dispatch) => {
        dispatch({
          type: GET_STORE_DATA,
          payload: JSON.parse(storeLocalStorage),
        });
      };
    }
  }

  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .get("stores")
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        if (!data.store) {
          dispatch({ type: GET_STORE_DATA, payload: emptyStore });
        } else {
          if (saveLocalStorage) {
            localStorage.setItem("store", JSON.stringify(data.store));
          }

          dispatch({ type: GET_STORE_DATA, payload: data.store });
        }
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};

//Obtener todas las tiendas
export const onGetStores = (setStores) => {
  return (dispatch) => {
    jwtAxios
      .get("stores-list")
      .then(({ data }) => {
        dispatch({ type: FETCH_SUCCESS });
        if (data.stores) {
          setStores(data.stores);
        }
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
        //dispatch({ type: FETCH_ERROR, payload: error.response.status });
      });
  };
};

//Creación, actualización de tienda y actualización de los datos del usuario
export const onCreateUpdateStoreUser = (
  data,
  onSetIsEditForm,
  changeLocale,
  setFieldValue,
  setContentLanguage,
  token
) => {
  //Eliminar campos de password si está vacio
  if (data.user.password === "") {
    delete data.user.password;
    delete data.user.password2;
  }
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    const requestUser = jwtAxios.patch("users/update", data.user);
    //Si no llega id es una creación, si llega una actualización
    const requestStore = !data.store.id
      ? jwtAxios.post("stores", data.store)
      : jwtAxios.patch("stores", data.store);
    axios
      .all([requestUser, requestStore])
      .then(
        axios.spread((...responses) => {
          const responseUser = responses[0];
          const responseStore = responses[1];
          if (responseUser.data.success && responseStore.data.success) {
            delete responseUser.data.user.password;
            delete responseUser.data.user.password2;
            dispatch({
              type: GET_JWT_USER_DATA,
              payload: responseUser.data.user,
            });
            localStorage.setItem(
              "jwt-auth-user",
              JSON.stringify(responseUser.data.user)
            );
            //Cambiar el idioma del interfaz en relación al idioma del usuario
            localStorage.setItem("locale", responseUser.data.user.locale);
            changeLocale(
              Locale[responseUser.data.user.locale]
                ? Locale[responseUser.data.user.locale]
                : process.env.REACT_APP_DEFAULT_LOCALE_INTERFACE
            );
            setContentLanguage(responseUser.data.user.locale);
            localStorage.setItem("locale", responseUser.data.user.locale);
            //Actualizar id de tienda para el guardado.
            setFieldValue("store.id", responseStore.data.store.id);
            dispatch(onSetIsEditForm(false));

            dispatch(onGetStore(token, true));

            dispatch({ type: FETCH_SUCCESS });
            dispatch({
              type: SHOW_MESSAGE,
              payload: responseUser.data.message,
            });
          } else {
            if (responseUser.data.errors.validate.password) {
              dispatch({
                type: FETCH_ERROR,
                payload: responseUser.data.errors.validate.password,
              });
            }
            if (responseUser.data.errors.validate.email) {
              dispatch({
                type: FETCH_ERROR,
                payload: responseUser.data.errors.validate.email,
              });
            }
          }
        })
      )
      .catch((error) => {
        InvalidToken(error, dispatch);
      });
  };
};

//Creación, actualización de tienda y actualización de los datos del usuario
export const onCreateUpdateStore = (data, onSetIsEditForm, messages, token) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    //Si no llega id es una creación, si llega una actualización
    const requestStore = !data.store.id
      ? jwtAxios.post("stores", data.store)
      : jwtAxios.patch("stores", data.store);
    requestStore
      .then(({ data }) => {
        if (data.success) {
          // dispatch({ type: GET_STORE_DATA, payload: data.store });
          dispatch(onSetIsEditForm(false));
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
        } else {
          if (data.errors.validate.store_addresses) {
            if (data.errors.validate.store_addresses.address) {
              dispatch({
                type: FETCH_ERROR,
                payload:
                  messages["general_data.address"] +
                  ": " +
                  data.errors.validate.store_addresses.address["_empty"],
              });
            }
          }
        }
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};

//Subida de la imagen del logotipo
export const onUploadLogo = (file, type, setFieldValue, token) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    const formData = new FormData();
    formData.append("filename", file.file);
    jwtAxiosFiles.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxiosFiles
      .post("uploads/" + type, formData)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          setFieldValue("store.logo", data.upload);
        } else {
          if (data.errors.validate.filename) {
            dispatch({
              type: FETCH_ERROR,
              payload: data.errors.validate.filename,
            });
          }
        }
      })
      .catch(function (error) {
        InvalidToken(error, dispatch);
      });
  };
};

//Subida de las imágenes de la tienda
export const onUploadPhotos = (
  imageList,
  addUpdateIndex,
  type,
  values,
  arrayHelpers,
  locales,
  token
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    let requests = [];

    forEach(addUpdateIndex, (index) => {
      const formData = new FormData();
      formData.append("filename", imageList[index].file);
      jwtAxiosFiles.defaults.headers.common["Authorization"] =
        "Bearer " + token;
      requests.push(jwtAxiosFiles.post("uploads/" + type, formData));
    });
    if (requests) {
      axios
        .all(requests)
        .then(
          axios.spread((...responses) => {
            forEach(responses, (response, index) => {
              if (response.data.success) {
                dispatch({ type: FETCH_SUCCESS });
                dispatch({
                  type: SHOW_MESSAGE,
                  payload: response.data.message,
                });
                //Crear el elemento para las traducciones
                response.data.upload._translations = {};
                response.data.upload.main = false;
                //Inicializar a vacio las traducciones
                forEach(locales, (locale) => {
                  response.data.upload._translations[locale.iso3] = {
                    title: "",
                  };
                });
                //Guardar foto para tienda
                if (!isUndefined(values.store)) {
                  arrayHelpers.insert(
                    values.store.photos.length,
                    response.data.upload
                  );
                }
                //Guardar foto para producto
                if (!isUndefined(values.product_data)) {
                  arrayHelpers.insert(
                    values.product_data.photos.length,
                    response.data.upload
                  );
                }
              } else {
                if (response.data.errors.validate.filename) {
                  dispatch({
                    type: FETCH_ERROR,
                    payload: response.data.errors.validate.filename,
                  });
                }
              }
            });
          })
        )
        .catch((error) => {
          InvalidToken(error, dispatch);
        });
    }
  };
};

//Creación, actualización de tienda y actualización de los datos del usuario
export const onUpdateUser = (
  data_user,
  setContentLanguage,
  changeLocale,
  token
) => {
  return (dispatch) => {
    dispatch({ type: FETCH_START });
    jwtAxios.defaults.headers.common["Authorization"] = "Bearer " + token;
    jwtAxios
      .patch("users/update", data_user)
      .then(({ data }) => {
        if (data.success) {
          dispatch({ type: FETCH_SUCCESS });
          dispatch({ type: SHOW_MESSAGE, payload: data.message });
          setContentLanguage(data.user.locale);
          localStorage.setItem("locale", data.user.locale);
          changeLocale(Locale[data.user.locale]);
        }
      })
      .catch((error) => {
        InvalidToken(error, dispatch);
      });
  };
};
