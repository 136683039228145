import React, { useState, useContext } from "react";
import AppContext from "../../../../@crema/utility/AppContext";
import { useDispatch, useSelector } from "react-redux";
import { map, contains, without, isEmpty, findWhere, isArray, filter } from "underscore";
import { makeStyles } from "@material-ui/core/styles";
import clsx from 'clsx';


import {
  DialogActions,
  DialogContent,
  Dialog,
  Button,
  Grid,
  DialogTitle,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core/";
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import GridContainer from "@crema/core/GridContainer";
import {
  onSetShowDialogAttributes,
  onSetShowDialogOk,
  onSetShowDialogConfirm,
  onGetCombinations,
  onCreateProductAttributes
} from "../../../../redux/actions";
import IntlMessages from "../../../../@crema/utility/IntlMessages";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import AlertFormOk from "../../../../../src/modules/muiComponents/feedback/Dialogs/AlertFormOk";
import AlertFormConfirm from "../../../../../src/modules/muiComponents/feedback/Dialogs/AlertFormConfirm";

export default function ModalFormAttributes({
  group_attributes, //Todos los grupos de atributos de la categoría
  product_attributes, //Atributos ya seleccionado previamente para marcar los ya seleccionados
  setFieldValue, //Para poder asignar los atributos
}) {

  const show_dialog_attributes = useSelector(
    ({ forms }) => forms.show_dialog_attributes
  );

  const { contentLanguage } = useContext(AppContext);

  const dispatch = useDispatch();

  const [selected_attributes, setSelectedAttributes] = useState([]);
  const [selected_combinations, setSelectedCombinations] = useState(null);

  function handleClose() {
    setSelectedAttributes([]);
    setSelectedCombinations([]);
    dispatch(onSetShowDialogAttributes(false));
  }

  function handleDisagree() {
    setSelectedAttributes([]);
    setSelectedCombinations([]);
    dispatch(onSetShowDialogAttributes(false));
  }

  function handleContinue() {
    if (selected_attributes.length === 0) {
      setDialogOk({
        title: "product.no.attributes",
        message: "product.no.attributes.message",
      });
      dispatch(onSetShowDialogOk(true));
    } else {
      dispatch(onGetCombinations(selected_attributes, setSelectedCombinations, product_attributes));
    }
  }

  function handleGenerate() {
    if (!findWhere(selected_combinations.combinations,{selected:true})) {
      setDialogOk({
        title: "product.no.combinations",
        message: "product.no.combinations.message",
      });
      dispatch(onSetShowDialogOk(true));
    }
    else {
      selected_combinations.combinations = filter(selected_combinations.combinations, combination =>{
        return combination.selected
      })
      setDialogConfirm({
        title: "product.alert.stock",
        message: "product.alert.stock.message",
        function_close: onSetShowDialogAttributes
      });
      dispatch(onCreateProductAttributes(selected_combinations, setFieldValue, product_attributes, onSetShowDialogConfirm, setSelectedAttributes, setSelectedCombinations));
    }
  }

  /*ESTILOS PERSONALIZADOS DEL COMPONENTE */
  const useStyles = makeStyles((theme) => {
    return {
      title: {
        textTransform: "uppercase",
        marginBottom: 15,
      },
      attributes: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        [theme.breakpoints.down("xs")]: {
          width: "100%",
        },
      },
      dialogPaper: {
        minHeight: "80vh",
        minWidth: "calc(100vw - 40px)",
        [theme.breakpoints.up("lg")]: {
          "&.columns-2":{
            minWidth: "calc(70vw - 40px)",
          },
          "&.columns-1":{
            minWidth: "calc(40vw - 40px)",
          }
        },
      },
      attribute: {
        display: "flex",
        alignItems: "center",
        marginRight: 60,
        "& label": {
          minWidth: 180,
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 0,
          [theme.breakpoints.down("xs")]: {
            // minWidth: 150,
            width: "100%"
          },
        },
        [theme.breakpoints.down("xs")]: {
          marginRight: 20,
        },
        "& .MuiFormControlLabel-label" :{
          [theme.breakpoints.down("xs")]: {
            textOverflow : "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: 100
          }, 
        },
      },
      attribute2: {
        display: "flex",
        alignItems: "center",
        marginRight: 60,
        "& label": {
          minWidth: 180,
          display: "flex",
          justifyContent: "space-between",
          marginLeft: 0,
          [theme.breakpoints.down("xs")]: {
            width: "100%"
          },
        },
        [theme.breakpoints.down("xs")]: {
          marginRight: 20,
        },
      },
      color: {
        backgroundColor: "#ccc",
        height: 15,
        width: 15,
        marginRight: 10,
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(0.5), // theme.spacing.unit / 2,
        top: theme.spacing(0.5), // theme.spacing.unit / 2,
        color: theme.palette.grey[500],
      },
      selected : {
        padding: 9,
        color: "#f44336"
      },
    };
  });
  /*FIN ESTILOS PERSONALIZADOS DEL COMPONENTE */

  const theme = useTheme();
  const classes = useStyles();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const [dialog_ok, setDialogOk] = useState({
    title: "",
    message: "",
    function_close: null
  });

  const [dialog_confirm, setDialogConfirm] = useState({
    title: "",
    message: ""
  });

  return show_dialog_attributes && group_attributes.length > 0 ? (
    <>
      <Dialog
        open={show_dialog_attributes}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
        // className={clsx(classes.dialogPaper, 'asd')}
        // classes={{ paper: classes.dialogPaper }}
        classes={{ paper: clsx(classes.dialogPaper, 'columns-'+group_attributes.length)}}
      >
        {isEmpty(selected_combinations) ? (
          <>
            <DialogTitle id="alert-dialog-title">
              <IntlMessages id="product.add.category" />
            </DialogTitle>
            <DialogContent>
              <GridContainer>
                {map(group_attributes, (group_attribute) => {
                  return (
                    <Grid
                      key={group_attribute.id}
                      item
                      xs={group_attributes.length>1 ? 6 : 12}
                      sm={group_attributes.length>1 ? 6 : 12}
                      md={group_attributes.length>1 ? 6 : 12}
                      lg={group_attributes.length>1 ? 6 : 12}
                    >
                      <div>
                        <h5 className={classes.title}>
                          {`${
                            group_attribute._translations[contentLanguage].title
                              ? group_attribute._translations[contentLanguage]
                                  .title
                              : ""
                          }`}{" "}
                        </h5>
                      </div>
                      <div className={classes.attributes}>
                        {map(group_attribute.attributes, (attribute) => {
                          return (
                            <div
                              key={attribute.id}
                              className={classes.attribute}
                            >
                              {group_attribute.group_type==="color" ? (
                                <div
                                  className={classes.color}
                                  style={{ backgroundColor: attribute.color }}
                                ></div>
                              ) : null}
                              <FormControlLabel
                                label={attribute._translations ? attribute._translations[contentLanguage].title.toUpperCase() : attribute.title.toUpperCase()}
                                labelPlacement="start"
                                control={
                                  <Checkbox
                                    name={attribute.id.toString()}
                                    value={attribute.id}
                                    inputProps={{
                                      "aria-label": "primary checkbox",
                                    }}
                                    onChange={(element, index) => {
                                      if (element.target.checked) {
                                        if (
                                          !contains(
                                            selected_attributes,
                                            parseInt(element.target.value)
                                          )
                                        ) {
                                          setSelectedAttributes([
                                            ...selected_attributes,
                                            parseInt(element.target.value),
                                          ]);
                                        }
                                      } else {
                                        if (
                                          contains(
                                            selected_attributes,
                                            parseInt(element.target.value)
                                          )
                                        ) {
                                          const temp = [...selected_attributes];
                                          setSelectedAttributes(
                                            without(
                                              temp,
                                              parseInt(element.target.value)
                                            )
                                          );
                                        }
                                      }
                                    }}
                                  />
                                }
                              />
                            </div>
                          );
                        })}
                      </div>
                    </Grid>
                  );
                })}
              </GridContainer>
            </DialogContent>
            <DialogActions style={{marginBottom:10}}>
              <Button
                onClick={handleDisagree}
                color="primary"
                variant="contained"
              >
                <IntlMessages id="app.cancel" />
              </Button>
              <Button
                type="button"
                onClick={handleContinue}
                color="secondary"
                autoFocus
                variant="contained"
              >
                <IntlMessages id="app.continue" />
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogTitle id="alert-dialog-title">
              <IntlMessages id="product.add.combinations" />
            </DialogTitle>
            <DialogContent>
              <GridContainer>
                {map(
                  selected_combinations.combinations,
                  (combination, index) => {
                    if (!isArray(combination.ids)){
                      combination.ids = [combination.ids]
                    }
                    return (
                      <Grid key={index} item xs={12} sm={6} md={6} lg={6} style={{paddingBottom:0, paddingTop:10}}>
                        <div key={index} className={classes.attribute2}>
                          {combination.color ? (
                            <div
                              className={classes.color}
                              style={{ backgroundColor: combination.color }}
                            ></div>
                          ) : null}
                          <FormControlLabel
                            label={combination.title.toUpperCase()}
                            labelPlacement="start"
                            control={
                              !combination.exist ?
                              <Checkbox
                                name={index.toString()}
                                value={combination.ids.join("-")}
                                checked={combination.selected ? true : false}
                                inputProps={{
                                  "aria-label": "primary checkbox",
                                }}
                                onChange={(element, index) => {
                                  let temp = {...selected_combinations};
                                  const ids_marcados= element.target.value.split("-").map(function(item) {
                                    return parseInt(item);
                                  }) 
                                  temp.combinations = map(temp.combinations, combination_check =>{   
                                    if(combination_check.ids[0]===ids_marcados[0] && combination_check.ids[1]===ids_marcados[1]){
                                      combination_check.selected=element.target.checked;
                                    }
                                    return combination_check;
                                  });
                                  setSelectedCombinations(temp);
                                }}
                              />
                              : <span className={classes.selected}><IntlMessages id="product.attribute.selected" /></span>
                            }
                          />
                        </div>
                      </Grid>
                    );
                  }
                )}
              </GridContainer>
            </DialogContent>
            <DialogActions style={{marginBottom:10}}>
              <Button
                onClick={handleDisagree}
                color="primary"
                variant="contained"
              >
                <IntlMessages id="app.cancel" />
              </Button>
              <Button
                type="button"
                onClick={handleGenerate}
                color="secondary"
                autoFocus
                variant="contained"
              >
                <IntlMessages id="product.add.combinations.combine" />
              </Button>
            </DialogActions>
          </>
        )}
        <IconButton aria-label="Close" className={classes.closeButton} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </Dialog>
      <AlertFormOk
        title={dialog_ok.title}
        message={dialog_ok.message}
      ></AlertFormOk>
      <AlertFormConfirm
        title={dialog_confirm.title}
        message={dialog_confirm.message}
        function_close={dialog_confirm.function_close}
      ></AlertFormConfirm>
    </>
  ) : null;
}